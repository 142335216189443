/* 2.2 - Color - Start
================================================== */

// Overlay Setting - Start
.has_overlay {
	z-index: 1;
	position: relative;
	.overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		position: absolute;
	}
}
// Overlay Setting - End

// Social Solor - Start

.color_facebook {
	color: $color_facebook;
}

.color_twitter {
	color: $color_twitter;
}

.color_instagram {
	color: $color_instagram;
}

.color_linkedin {
	color: $color_linkedin;
}

.color_pinterest {
	color: $color_pinterest;
}

.color_threads {
	color: $color_threads;
}

.color_x {
	color: $color_x;
}

// Social Solor - End

/* 2.2 - Color - End
================================================== */