/* 3.23 - Home - Start
================================================== */

// Home Personal Portfolio - Start
.home_personal_portfolio {
	font-family: $secondary_font;
	
	h1,h2,h3,h4,h5,h6 {
		font-family: $secondary_font;
	}
}
// Home Personal Portfolio - End

/* 3.23 - Home - End
================================================== */