/* 2.1 - Backtotop - Start
================================================== */
.backtotop {
	z-index: 999;
	position: fixed;
	display: none;
	right: 22px;
	bottom: 22px;
	width: 28px;
	.scroll {
		z-index: 1;
		position: fixed;
		display: block;
		right: 22px;
		bottom: 22px;
		width: 28px;
		height: 28px;
		font-size: 22px;
		color: #1e2740;
		line-height: 27px;
		text-align: center;
		border: 2px solid;
		border-color: #1e2740;
		border-radius: 50%;
		background-color: $color_white;
		box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.40);
	}

}

/* 2.1 - Backtotop - End
================================================== */