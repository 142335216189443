/* 3.17 - Team - Start
================================================== */

// Team Section - Start

.team_section {
	&.bg_gray {
		background-color: #F9F9F9;
	}
	&.bg_black {
		background-color: #000000;
	}
}
// Team Section - End

// Team Item 1 - Start

.team_item {
	transition: $transition;
	.team_image {
		overflow: hidden;
		position: relative;
	}
	.team_content {
		padding: 40px 30px 25px;
	}
	.team_name {
		line-height: 1;
		font-size: 30px;
		margin-bottom: 20px;
		letter-spacing: 0.13px;
		transition: $transition;
	}
	p {
		line-height: 1;
		color: #525252;
		font-size: 20px;
		margin-bottom: 15px;
		letter-spacing: 0.27px;
	}

	&:hover {
		background-color: #ffffff;
		box-shadow: 20px 20px 60px rgba(41, 41, 41, 0.2);
		.team_name {
			color: $color_primary;
		}
	}
}
// Team Item 1 - End


// Team Item 2 - Start

.team_item_2 {

	.team_image {
		overflow: hidden;
		position: relative;
		margin-bottom: 35px;
		&:before {
			top: 0;
			width: 0;
			left: 50%;
			bottom: 0;
			z-index: 1;
			content: '';
			opacity: 0.85;
			position: absolute;
			background-color: rgba(13, 10, 20, 0.50);
			transform: translateX(-50%);
			transition: $transition;
		}
	}

	.team_social {
		z-index: 2;
		right: 20px;
		bottom: 20px;
		overflow: hidden;
		text-align: center;
		position: absolute;
		&:before {
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			content: '';
			height: 45px;
			position: absolute;
			background-color: #ffffff;
			transition: 0.4s cubic-bezier(0.16, 1, 0.3, 1);
		}
	}
	
	.plus_btn {
		transition: $transition;
		border-top: 1px solid transparent;
	}
	
	.plus_btn,
	.social_primary a {
		width: 45px;
		height: 45px;
		display: flex;
		color: #282828;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
	}

	.social_primary {
		>li {
			margin: 0;
			transform: translateX(55px);
			transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
		}
		
		a {
			z-index: 1;
			font-size: 15px;
			&:hover {
				color: #ffffff;
			}
			
			&:before {
				z-index: -1;
				height: 100%;
			}
		}

		.facebook a:before {
			background-color: $color_facebook;
		}
		.twitter a:before {
			background-color: $color_twitter;
		}
		.linkedin a:before {
			background-color: $color_linkedin;
		}
		.youtube a:before {
			background-color: $color_youtube;
		}
		.instagram a:before {
			background-color: $color_instagram;
		}
		.x a:before {
			background-color: $color_x;
		}
		.pinterest a:before {
			background-color: $color_pinterest;
		}
		.color_threads a:before {
			background-color: $color_threads;
		}	
	
	}

	.team_name {
		line-height: 1;
		font-size: 32px;
		margin-bottom: 10px;
		letter-spacing: -0.02em;
	}

	.team_title {
		color: #9e9e9e;
		display: block;
		line-height: 1;
		font-size: 18px;
		letter-spacing: 0.03em;
	}

	&:hover {
		.team_image {
			&:before {
				width: 100%;
			}
		}

		.team_social {
			&:before {
				height: 100%;
				transition-delay: 0.2s;
			}
		}

		.plus_btn {
			border-color: rgba(0, 0, 0, 0.1);
		}
		
		.social_primary {
			>li {
				transform: translateX(0px);
				&:nth-child(5) {
					transition-delay: 0.3s;
				}
				&:nth-child(4) {
					transition-delay: 0.4s;
				}
				&:nth-child(3) {
					transition-delay: 0.5s;
				}
				&:nth-child(2) {
					transition-delay: 0.6s;
				}
				&:nth-child(1) {
					transition-delay: 0.7s;
				}
			}
		}
	}
}

// Team Item 2 - End


// Team Item 3 - Start

.team_item_3 {
	border: 1px solid #F2F2F2;
	background-color: #ffffff;
	transition: $transition;

	.team_image {
		overflow: hidden;
		position: relative;
	}

	.team_social {
		top: 30px;
		right: 30px;
		z-index: 1;
		position: absolute;
		text-align: center;

		>li {
			transform: translateX(60px);
			transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
			&:not(:last-child) {
				margin: 0 0 20px;
			}
		}

		a {
			color: #ffffff;
			display: block;
			line-height: 1;
			&:hover {
				opacity: 0.5;
			}
		}
	}

	.image_wrap {
		display: block;
		overflow: hidden;
		background-color: #FF6D54;
		img {
			transition: $transition;
		}
	}

	.team_content {
		padding: 30px;
	}

	.team_name {
		font-size: 36px;
		margin-bottom: 0;
		line-height: 46px;
		letter-spacing: -0.08em;
		a {
			color: #2b2c30;
		}
	}

	.team_title {
		color: #5a5c62;
		display: block;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 0.03em;
	}

	&:hover {
		box-shadow: 0px 20px 30px rgba(204, 204, 204, 0.5);

		.image_wrap {
			img {
				opacity: 0.1;
			}
		}

		.team_social {
			>li {
				transform: translateX(0px);
				&:nth-child(1) {
					transition-delay: 0.2s;
				}
				&:nth-child(2) {
					transition-delay: 0.3s;
				}
				&:nth-child(3) {
					transition-delay: 0.4s;
				}
				&:nth-child(4) {
					transition-delay: 0.5s;
				}
			}
		}
	}
}
// Team Item 3 - End

/* 3.17 - Team - End
================================================== */