/* 3.11 - Blog - Start
================================================== */

// Blog Section - Start
.blog_section {
	&.bg_gray {
		background-color: #f9f9f9;
	}
	&.bg_gray_2 {
		background-color: #F7F7F7;
	}
	&.bg_gray_3 {
		background-color: #FCFCFC;
	}
}
// Blog Section - End

// Blog Column - Start
.blog_3col_masonry {
	margin: -30px;
	.element-item {
		padding: 30px;
		width: 33.333%;
	}
}
// Blog Column - End

// Recent Post - Start
.recent_post_list {
	>li {
		&:not(:last-child) {
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid #D6D6D6;
		}
	}
}

.recent_post {
	.item_title {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 20px;
		a {
			color: #525252;
			&:hover {
				color: $color_primary;
			}
		}
	}
	.post_date {
		color: #A3A3A3;
		display: block;
		line-height: 1;
		font-size: 16px;
	}

	&:hover {
		.item_title {
			a {
				text-decoration: underline;
			}
		}
	}
}
// Recent Post - End

// Blog Full Width - Start

.blog_fullwidth {
	display: flex;
	padding: 90px 0;
	min-height: 760px;
	position: relative;
	align-items: flex-End;
	
	@include bg_image_property;
	.overlay {
		background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), #fff);
	}
	.post_meta {
		margin-bottom: 30px;
		color: $color_white;
		a {
			color: $color_white;
			&:hover {
				color: $color_grey_white;
			}
		}
	}
	.item_title {
		color: $color_white;
		font-size: 56px;
		margin-bottom: 20px;
		letter-spacing: -1px;
	}
	p {
		color: $color_white;
		margin-bottom: 30px;
	}
	.btn_text {
		color: $color_white;
		span {
			border-color: $color_white;
		}
	}
}
// Blog Full Width - End

// Blog Standard - Start

.blog_standard {
	margin-bottom: 90px;
	.item_image {
		display: block;
		overflow: hidden;
		aspect-ratio: 16/9;
		margin-bottom: 30px;
		img {
			transition: $transition;
		}
	}
	.post_meta {
		margin-bottom: 30px;
	}

	.item_title {
		font-size: 36px;
		margin-bottom: 25px;
		a {
			color: $color_black;
			&:hover {
				color: $color_primary;
			}
		}
	}
	p {
		margin-bottom: 20px;
	}

	&:hover {
		.item_image {
			img {
				transform: scale(1.08);
			}
		}
		.item_title {
			a {
				text-decoration: underline;
			}
		}
	}
}
// Blog Standard - End

// Blog Item Boxed - Start
.blog_boxed_wrapper {
	display: grid;
	grid-column-gap: 30px;
	grid-template-columns: repeat(3,1fr);
}

.blog_item_boxed {
	padding: 60px 30px;
	position: relative;
	transition: $transition;
	border-bottom: 5px solid #DBDBDB;
	.blog_category {
		margin-bottom: 20px;
		li {
			position: relative;
			&:not(:last-child) {
				margin-right: 6px;
				padding-right: 4px;
			}
			&:after {
				right: 0;
				bottom: 0;
				content: ',';
				color: #909090;
				position: absolute;
				transition: $transition;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
		a {
			color: #909090;
			&:hover {
				color: #ffffff;
			}
		}
	}

	p {
		font-size: 25px;
		line-height: 1.5;
		letter-spacing: 0;
		margin-bottom: 40px;
		color: $color_black;
		transition: $transition;
	}

	.post_date {
		display: block;
		line-height: 1;
		font-weight: 700;
		color: $color_black;
		transition: $transition;
	}

	.global_link {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		position: absolute;
	}

	&:hover {
		color: #ffffff;
		border-color: $color_primary;
		background-color: $color_primary;

		.blog_category {
			li {
				&:after {
					color: #A4A4FF;
				}
			}
			a {
				color: #A4A4FF;
				&:hover {
					color: #ffffff;
				}
			}
		}

		p,
		.post_date {
			color: #ffffff;
		}
	}
}
// Blog Item Boxed - End

// Blog Grid - Start
.blog_grid {
	transition: $transition;
	background-color: #ffffff;

	&.has_border {
		border: 2px solid #F2F2F2;
	}

	.item_image {
		display: block;
		overflow: hidden;
		img {
			transition: $transition;
		}
	}

	.item_content {
		padding: 30px;
	}

	.post_date {
		line-height: 1;
		color: #818291;
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 20px;
	}

	.item_title {
		font-size: 26px;
		margin-bottom: 30px;
		letter-spacing: -0.02em;
		a {
			color: #000;
		}
	}

	.btn_text {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		text-transform: lowercase;
		span {
			padding-bottom: 0;
			border-bottom-width: 1px;
			&:before {
				height: 1px;
				bottom: -1px;
			}
		}
	}

	.item_author {
		display: flex;
		align-items: center;
		.author_image {
			width: 50px;
			height: 50px;
			flex: 0 0 50px;
			overflow: hidden;
			margin: 0 20px 0 0;
			border-radius: 100%;
		}
		.author_title {
			color: #2b2c30;
			font-size: 14px;
			small {
				color: #737373;
				font-size: 14px;
			}
		}
	}

	&:hover {
		border-color: #ffffff;
		box-shadow: 0px 20px 30px rgba(204, 204, 204, 0.5);

		.item_image {
			img {
				transform: scale(1.08);
			}
		}
		.item_title {
			a {
				color: $color_primary;
			}
		}
	}
}
// Blog Grid - End

// Blog Item Boxed 2 - Start
.blog_item_boxed_2 {
	padding: 65px 40px 60px;
	background-color: #ffffff;
	transition: $transition;
	box-shadow: 0px 0px 10px rgba(219, 219, 219, 0.5);

	.post_meta {
		margin-bottom: 39px;
		>li {
			color: #5a5c62;
			font-size: 14px;
			line-height: 24px;
			position: relative;
			letter-spacing: 0.03em;
			transition: $transition;
			&:not(:last-child) {
				margin-right: 24px;
				padding-right: 24px;
			}
			&:after {
				top: 50%;
				content: '';
				right: -5px;
				width: 10px;
				height: 2px;
				position: absolute;
				background-color: #5A5C62;
				transform: translateY(-50%);
				transition: $transition;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
		a {
			color: #5a5c62;
			&:hover {
				color: $color_danger;
			}
		}
	}

	.item_title {
		font-size: 36px;
		line-height: 46px;
		margin-bottom: 25px;
		a {
			color: #2a2b2f;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	p {
		color: #5a5c62;
		font-size: 17px;
		line-height: 32px;
		margin-bottom: 41px;
		letter-spacing: 0.03em;
		transition: $transition;
	}

	.post_admin {
		align-items: center;
		display: inline-flex;
		.admin_image {
			width: 60px;
			height: 60px;
			overflow: hidden;
			margin-right: 20px;
			border-radius: 100%;
		}
		.admin_name {
			color: #5a5c62;
			font-size: 18px;
			line-height: 32px;
			letter-spacing: 0.03em;
			transition: $transition;
		}
	}

	&:hover {
		background-color: $color_danger;
		p,
		.item_title a,
		.post_meta li,
		.post_meta a,
		.post_admin .admin_name {
			color: #ffffff;
		}
		.post_meta li:after {
			background-color: #ffffff;
		}
	}
}
// Blog Item Boxed 2 - End

// Blog Boxed Image - Start
.blog_boxed_image {
	z-index: 1;
	overflow: hidden;
	position: relative;

	&:before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		content: '';
		position: absolute;
		transition: $transition;
		background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
	}

	img {
		transition: $transition;
	}

	.item_content {
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		padding: 30px;
		position: absolute;
	}

	.item_title {
		color: #fff;
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 30px;
	}

	.item_author {
		display: flex;
		align-items: center;
		.author_image {
			width: 50px;
			height: 50px;
			flex: 0 0 50px;
			overflow: hidden;
			margin: 0 20px 0 0;
			border-radius: 100%;
		}
		.author_title {
			color: #ffffff;
			font-size: 14px;
			small {
				display: block;
				color: #D5D5D5;
				font-size: 14px;
			}
		}
	}

	.global_link {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		position: absolute;
	}

	&:hover {
		img {
			transform: scale(1.08);
		}
	}
}
// Blog Boxed Image - End

/* 3.11 - Blog - End
================================================== */