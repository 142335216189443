/* 3.20 - Testimonial - Start
================================================== */

// Testimonial Section - Start
.testimonial_section {
	@include bg_image_property;
	&.bg_gray {
		background-color: #F7F7F7;
	}
	.overlay {
		background-color: rgba(255, 82, 82, 0.9);
	}
}
// Testimonial Section - End

// Testimonial Item 1 - Start
.testimonial_item_1 {
	p {
		font-size: 56px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 50px;
		letter-spacing: .8px;
	}
	h3 {
		color: #8C8C8C;
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 60px;
	}
}

.testimonial_carousel {
	.slick-dots {
		display: flex;
		position: static;
		justify-content: flex-start;
		li {
			&:not(:last-child) {
				margin-right: 20px;
			}
			&:hover,
			&.slick-active {
				button {
					opacity: 1;
				}
			}
		}
		button {
			opacity: .1;
			width: 14px;
			height: 14px;
			display: block;
			background-color: #ffffff;
		}
	}
}

.testimonial_item_1.style_2 {
	.quote_icon {
		margin-bottom: 70px;
	}
	p {
		color: #d0d0d0;
		font-size: 48px;
		font-weight: 300;
		line-height: 58px;
		margin-bottom: 40px;
		letter-spacing: -0.04em;
	}
	h3 {
		color: #727685;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: -0.06em;
		span {
			display: block;
			color: #d0d0d0;
			font-size: 26px;
			font-weight: 700;
			letter-spacing: -0.04em;
		}
	}
}

.testimonial_tab {
	.nav {
		width: 467px;
		height: 448px;
		position: relative;
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		li {
			position: absolute;
			&:nth-child(1) {
				top: -27px;
				left: 94px;
			}
			&:nth-child(2) {
				top: 104px;
				right: 18px;
			}
			&:nth-child(3) {
				top: 186px;
				left: 107px;
			}
			&:nth-child(4) {
				top: 192px;
				right: 124px;
			}
			&:nth-child(5) {
				top: 221px;
				right: -12px;
			}
			&:nth-child(6) {
				left: 24px;
				bottom: 104px;
			}
			&:nth-child(7) {
				right: 64px;
				bottom: 82px;
			}
		}

		button {
			overflow: hidden;
			border-radius: 100%;
			&:hover,
			&.active {
				box-shadow: 14px 26px 40px 0 rgba(22, 26, 44, 0.6);
			}
		}
	}
	.tab-content {
		padding-right: 40px;
	}
}
// Testimonial Item 1 - End

// Testimonial Item 2 - Start
.testimonial_carousel_2 {
	margin-bottom: 100px;
	.slider_item {
		padding: 15px;
	}
}

.testimonial_item_2 {
	background: #ffffff;
	box-shadow: 0px 5px 20px #f0f0f0;
	.content_wrap {
		padding: 55px 35px;
		text-align: center;
	}
	.testimonial_thumbnail {
		width: 140px;
		height: 140px;
		overflow: hidden;
		margin-bottom: 40px;
		border-radius: 100%;
		display: inline-block;
	}
	p {
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 0;
		letter-spacing: -0.02em;
	}
	.bottom_area {
		display: flex;
		padding: 25px 35px;
		align-items: center;
		border-top: 1px solid #e0e0e0;
		justify-content: space-between;
	}
	h3 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 0;
	}
}
// Testimonial Item 2 - End

// Testimonial Item 3 - Start
.testimonial_carousel {
	.carousel_arrow {
		padding-top: 90px;
		button {
			&:hover {
				color: #ffffff;
			}
		}
	}
}

.testimonial_item_3 {
	position: relative;
	padding-right: 130px;

	p {
		font-size: 46px;
		font-weight: 300;
		line-height: 1.4;
		margin-bottom: 30px;
	}

	.admin_chip {
		display: flex;
		align-items: center;
		.admin_image {
			flex: 0 0 70px;
			overflow: hidden;
			margin-right: 20px;
			border-radius: 100%;
		}
		.admin_name {
			line-height: 1;
			font-size: 26px;
			margin-bottom: 2px;
		}
		.admin_designation {
			display: block;
			color: #dbdbdb;
			font-size: 18px;
		}
	}

	.quote_icon {
		left: 50%;
		bottom: 0;
		display: block;
		line-height: 0;
		font-size: 200px;
		font-weight: 700;
		position: absolute;
		color: rgba(255, 255, 255, 0.1);
	}
}
// Testimonial Item 3 - End

// Testimonial Item 4 - Start
.testimonial_item_4 {
	padding: 68px 30px 60px;
	border: 2px solid #F8F8F8;
	transition: $transition;

	p {
		color: #2a2b2f;
		font-size: 36px;
		font-weight: 700;
		line-height: 46px;
		margin-bottom: 47px;
	}

	.testimonial_admin {
		display: flex;
		align-items: center;
	}

	.admin_image {
		width: 80px;
		height: 80px;
		overflow: hidden;
		margin-right: 25px;
		border-radius: 100%;
	}

	.admin_name {
		color: #2a2b2f;
		font-size: 26px;
		font-weight: 500;
		line-height: 36px;
		margin-bottom: 5px;
	}

	.admin_designation {
		color: #2c405e;
		display: block;
		font-size: 14px;
		line-height: 24px;
	}

	p,
	.admin_name,
	.admin_designation {
		transition: $transition;
	}

	&:hover {
		border-color: $color_danger;
		background-color: $color_danger;
		p,
		.admin_name,
		.admin_designation {
			color: #ffffff;
		}
	}
}
// Testimonial Item 4 - End

/* 3.20 - Testimonial - End
================================================== */