
// Global Variables

$color_info: #803CFF;
$color_black: #0D0A14;
$color_white: #ffffff;
$color_danger: #CF404D;
$color_primary: #6b55b2;
$color_default: #1e2740;
$color_shadow: #262541;
$color_grey: #5a5c62;
$color_grey_hr: #f5f5f5;
$color_grey_white: #f4f7fa;
$color_grey_dark: #28262e;
$background_color: #301a4b;
$background_color_transparente: rgba(48, 26, 75, 0.80);

// Brand color

$color_brand:					#8977c1;
$color_brand_alternate: 		#6B55BA;
$color_brand_shadow: 			0 0 25px 0 rgba(151, 56, 12, 0.50)  !default;

// Social Solor

$color_facebook: 				#3b5998;
$color_twitter: 				#1da1f2;
$color_instagram: 				#dd2a7b;
$color_linkedin: 				#0077b5;
$color_pinterest: 				#bd081c;
$color_threads: 				#111111;
$color_x: 						#ffffff;
$color_youtube: 				#CD201F;

// Bg Color commons

$bg-color1:          	       	$color_white;
$bg-color2:          	     	#fafcf4;
$bg-color4:               	  	#f4f7fa;
$bg-color3: 					$color_brand_alternate;
$bg-color5:              		$color_default;
$bg-color6: 					#111111;
$bg-color7: 					$color_brand;
$bg-overlay:                	$color_white !default;

// Fonts Color commons

$font-color-base:        		$color_default;
$font-color1:            		$color_white;
$font-color2:            		#041e49;
$font-color3:             		#3b4664;
$font-color4:            	 	#f4f7fa;
$font-color5:            	 	#f7f6f3;
$font-color6:            	 	#111111;
$font-color7:            	 	$color_brand;
$placeholder-color:      	    #777777 !default;

// Heading color commons

$headings-color:          		$font-color-base;
$big-headers-color:    			$font-color-base;

// Links commons

$link-color:                	$color_brand !default;

// Form Color commons

$form-bg:                 		#ffffff !default;
$form-bg2:                		#1e2740;
$form-bg3: 						#777777;
$form-bg4:                		#f5f5f5;
$form-bg5:                		$color_brand;
$form-bg6:                		$color_brand_alternate;

$form-color:             		#ffffff !default;
$form-color2:            		#1e2740;
$form-color3:            		#777777;
$form-color4:					#f5f5f5;
$form-color5:             		$color_brand;
$form-color6:             		$color_brand_alternate;

$form-border:           		1px solid #e3e3e3 !default;
$form-border2:          	 	1px solid #1e2740;
$form-border3:          	 	1px solid #ffffff;
$form-border4:          	 	1px solid #f5f5f5;
$form-border5:          	 	1px solid $color_brand;
$form-border6:          	 	1px solid $color_brand_alternate;

$form-border-link:       		1px solid $link-color;

// Form Change commons

$form-change-bg:            	#ffffff !default;
$form-change-bg2:          		#1e2740;
$form-change-bg3:           	#f5f5f5;
$form-change-bg4:           	$color_brand;

$form-change-color:         	#ffffff !default;
$form-change-color2:        	#1e2740;
$form-change-color3:        	#777777;

$form-change-border:        	1px solid #e3e3e3 !default;
$form-change-border2:       	1px solid #1e2740;
$form-change-border3:       	1px solid #ffffff;
$form-change-border4:       	1px solid $color_brand;

// Spacing commons

$sectionspace: 					(40,50,60,70,80,90,100,130,140); 
$innerspace: 					(20,30,40,50,60,70,80,90); 
$sides: 						(top, bottom, left, right);
$sides2: 						(top, bottom);

$section-40:  					40px;
$section-50:  					50px;
$section-60: 					60px;
$section-70:  					70px;
$section-80:  					80px;
$section-90: 					90px;
$section-100:  					100px;
$section-130:  					130px;
$section-140: 					140px;

$inner-20:  					20px;
$inner-30:  					30px;
$inner-40: 						40px;
$inner-50:  					50px;
$inner-60:						60px;
$inner-70: 						70px;
$inner-80:  					80px;
$inner-90:  					90px;

// Typography commons

$body_font: 					'nunito', sans-serif !default;
$heading_font:					'Poppins', sans-serif !default;
$secondary_font: 				'Work Sans', sans-serif;
$code_font: 					'Source Code Pro', monospace;

$font-family-base:          	$body_font;
$font-family-header:			$heading_font;

$font-family-headings:     		$font-family-base;
$font-family-big: 				$font-family-header;

$font-size-base:             	18px !default;
$blockquote-font-size:          19px;
$small-font-size:            	16px;

$h1-font-size:        	     	$font-size-base * 1.36; //23.12px
$h2-font-size:             		$font-size-base * 1.30; //22.10px
$h3-font-size:             		$font-size-base * 1.24; //21.08px
$h4-font-size:             		$font-size-base * 1.19; //20.23px
$h5-font-size:             		$font-size-base * 1.15; //19.50px
$h6-font-size:             		$font-size-base * 1.10; //18.00px
$h7-font-size:             		$font-size-base;

$big-size1:                		$font-size-base * 2.2; //37.4px
$big-size2:                		$font-size-base * 2.0; //34.0px
$big-size3:                		$font-size-base * 1.9; //32.3px
$big-size4:                		$font-size-base * 1.8; //30.6px
$big-size5:                		$font-size-base * 1.7; //28.9px
$big-size6:                		$font-size-base * 1.6; //27.0px

$font-weight-light:         	300;
$font-weight-normal:        	400 !default;
$font-weight-medium:        	500;
$font-weight-semibold:      	600;
$font-weight-bold:          	700 !default;

$line-height-base:          	1.8 !default;
$line-height-headings:      	1.4 !default;
$line-height-big:          		1.4 !default;

// Form commons

$form-size:               		17px !default;
$form-size2:              		19px;
$form-size3:              		23px;

$form-radius:            		16px !default; 

$form-space-v:              	10px !default;
$form-space-h:              	30px !default;

// Component commons

$border-radius:             	16px !default;
$round-radius:              	50px !default;
$box-shadow:                	0 0 50px 0 rgba(200, 200, 200, 0.50)  !default;
$box-shadow-deep:          		0 0 10px 0 rgba(200, 200, 200, 0.40)  !default;
$box-shadow-spead:         		0 0 30px 0 rgba(200, 200, 200, 0.30)  !default;
$box-shadow-brand:         		$color_brand-shadow;
$transition-base:          		all 500ms ease !default;

// Responsive Layout commons

$desktop-device: 				1200px !default;
$laptop-device: 				992px;
$tablet-device: 				768px;
$mobile-device: 				576px;

// default transition

$transition: 0.2s cubic-bezier(0.25, 1, 0.5, 1);