/* 2.7 - Carousel - Start
================================================== */
.slick-dots {
	position: static;
	padding-top: 50px;
	li {
		display: inline-block;
		&:not(:last-child) {
			margin: 0 20px 0 0;
		}
		button {
			opacity: 1;
			width: 30px;
			height: 30px;
			display: block;
			border-radius: 100%;
			background-color: #E9E9E9;
			transition: $transition;
		}
		&:hover,
		&.slick-active {
			button {
				opacity: 1;
				background-color: $color_primary;
			}
		}
	}
}

.carousel_arrow {
	display: flex;
	align-items: center;
	button {
		font-size: 36px;
		color: $color_black;
		&:hover {
			color: $color_primary;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}
	}
}
/* 2.7 - Carousel - End
================================================== */