/* 1.2 - Template Global Reset - Start
================================================== */

body {
	margin: 0px;
	padding: 0px;
	font-size: 18px;
	font-weight: 400;
	line-height: $line-height-base;
	font-style: normal;
	color: $color_default;
	letter-spacing: 0.27px;
	font-family: $body_font;
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&.bg_dark {
		color: #D0D0D0;
		background-color: #1A1A1A;
	}
}

.body_wrap {
	overflow: hidden;
	position: relative;
}

iframe {
	border: none;
}

a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
	outline: none;
}

img:not([draggable]),
embed,
object,
video {
	height: auto;
	max-width: 100%;
}

img {
	border: none;
	height: auto;
	max-width: 100%;
	user-select: none;
	vertical-align: middle;
}

a {
	outline: 0;
	display: inline-block;
	text-decoration: none;
	transition: $transition;
	&:active,
	&:focus,
	&:hover,
	&:visited {
		outline: 0;
		text-decoration: none;
	}
}

button {
	padding: 0px;
	border: none;
	outline: none;
	background: none;
	display: inline-block;
	transition: $transition;
	&:focus {
		outline: none;
	}
}

h1,h2,h3,h4,h5,h6,h7 {
	font-weight: 700;
	color: $font-color-base;
	letter-spacing: 0.19px;
	font-family: $heading_font;
	margin: 25px 0;
}

hr {
	opacity: 1;
	height: 1px;
	margin: 25px 0;
	background-color: $color_grey_hr;
}

hr_trans {
	opacity: 0;
	height: 1px;
	margin: 25px 0;
	background-color: $color_white;
}

table {
	border-spacing: 1px;
	border: 2px solid #999;
}

td,
tr,
th {
	padding: 4px;
	border: 1px solid #999;
	text-align: center;
	margin: 25px 0;
}

dl, ol, ul {
    margin-top: 5px;
    margin-bottom: 5px;
}

ol, ul {
    padding-left: 2rem;
}

blockquote {
    margin: 40px 20px;
	font-weight: 500;
	line-height: $line-height-base;
	font-style: normal;
	font-size: 22px;
	color: $color_grey;
	letter-spacing: 0.8px;
	font-family: $body_font;

	cite {
	font-weight: 700;
	font-style: italic;
	font-size: 24px;
	}
}

*::selection {
	color: $color_white;
	background-color: $bg-color7;
}


/* 1.2 - Template Global Reset - End
================================================== */