/* 3.21 - Feature - Start
================================================== */
.feature_section {
	h2 {
		line-height: 1;
		font-size: 56px;
		margin-bottom: 30px;
		letter-spacing: -0.02em;
	}
	p {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.btn {
		margin-top: 10px;
	}
}
/* 3.21 - Feature - End
================================================== */