/* 2.16 - Blockquote - Start
================================================== */
figure {
	z-index: 1;
	color: $font-color-base;
	font-family: $code_font;
	overflow: hidden;
	position: relative;
	margin-bottom: 50px;
	padding: 50px 100px;
	border-radius: $border-radius;
	background-color: $color_grey_white;
		.quote_icon {
			top: 0;
			left: 0;
			z-index: -1;
			position: absolute;
		}
	
	p {
		font-size: 26px;
		margin-bottom: 0;
	}
  
	.blockquote-footer {
		margin: 0;
		line-height: 1;
		color: #ffffff;
		font-size: 26px;
		font-weight: 700;
		padding-top: 15px;
			&:before {
			display: none;
		}
	}
}
/* 2.16 - Blockquote - End
================================================== */