/* 3.18 - Call To Action - Start
================================================== */

// Call To Action Default - Start
.calltoaction_section {
	&.bg_gray {
		background-color: #F9F9F9;
	}
	&.bg_blue {
		background-color: $color_primary;
	}

	h2 {
		font-size: 26px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 30px;
		letter-spacing: 0.19px;
	}
	h3 {
		font-size: 72px;
		margin-bottom: 50px;
		letter-spacing: -1px;
	}

	p {
		font-size: 26px;
		margin-bottom: 35px;
	}

	.mail_text {
		display: block;
		line-height: 1;
		font-size: 56px;
		letter-spacing: -0.02em;
		>a {
			text-decoration: underline;
		}
	}
}
// Call To Action Default - End

// Call To Action Style 2 - Start
.calltoaction_style_2 {
	padding: 240px 0;
}
// Call To Action Style 2 - End

// Call To Action Boxed - Start
.calltoaction_boxed {
	padding: 160px;
	position: relative;
	background-color: $color_primary;
	&:before {
		top: 20px;
		left: 20px;
		content: '';
		z-index: -1;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: $color_black;
	}

	p {
		font-size: 26px;
		margin-bottom: 35px;
	}

	.mail_text {
		display: block;
		line-height: 1;
		font-size: 56px;
		letter-spacing: -0.02em;
		>a {
			text-decoration: underline;
		}
	}
}
// Call To Action Boxed - End

// Call To Action Section 2 - Start
.calltoaction_section_2 {
	display: flex;
	min-height: 740px;
	align-items: center;
	justify-content: center;
	background-color: #101010;
	h2 {
		color: #d0d0d0;
		font-size: 36px;
		margin-bottom: 20px;
		letter-spacing: -0.03em;
	}
	a {
		color: #803cff;
		font-size: 72px;
		font-weight: 700;
		letter-spacing: -0.04em;
	}
}
// Call To Action Section 2 - End

// Call To Action Section 3 - Start
.calltoaction_section_3 {
	display: flex;
	min-height: 686px;
	align-items: center;
	
	.overlay {
		background-color: rgba(255, 82, 82, 0.9);
	}

	h2 {
		color: #ffffff;
		font-size: 72px;
		margin-bottom: 0;
		letter-spacing: -2.5px;
	}
}
// Call To Action Section 3 - End

// Join Content Wrap - Start
.join_content_wrap {
	padding: 150px 30px;
	background-color: #FF6D54;
	h2 {
		color: #fff;
		font-size: 56px;
		line-height: 72px;
		margin-bottom: 26px;
		letter-spacing: -0.05em;
	}
	p {
		color: #fff;
		font-size: 26px;
		line-height: 36px;
		margin-bottom: 60px;
		letter-spacing: -0.04em;
	}

	.btn {
		overflow: hidden;
		border-radius: 50px;
		border-color: #FF5E15;
		box-shadow: 0px 10px 20px rgba(19, 19, 19, 0.3);
		&:hover {
			background-color: #FF5E15;
		}
	}

	.form_item {
		display: flex;
		margin: 0 auto;
		max-width: 950px;
		padding-top: 30px;
		align-items: center;
		justify-content: space-between;
		input {
			flex: 0 0 1;
			color: #ffffff;
			padding: 0 40px;
			margin-right: 30px;
			border-radius: 50px;
			border: 1px solid #ffffff;
			background-color: rgba(255, 255, 255, 0.05);
			&:-moz-placeholder {
				color: #ffffff;
			}
			&::-moz-placeholder {
				color: #ffffff;
			}
			&:-ms-input-placeholder {
				color: #ffffff;
			}
			&::-webkit-input-placeholder {
				color: #ffffff;
			}
		}
		button {
			height: 100px;
			color: #ffffff;
			flex: 0 0 330px;
			font-size: 18px;
			font-weight: 600;
			line-height: 100px;
			border-radius: 50px;
			background-color: $color_black;
			&:hover {
				background-color: $color_primary;
			}
		}
	}
}
// Join Content Wrap - End

/* 3.18 - Call To Action - End
================================================== */