/* 3.8 - About - Start
================================================== */

// About Section - Start

.about_section {
	.image_wrap {
		margin-bottom: 60px;
	}
	
	h2 {
		line-height: 1;
		font-size: 102px;
		margin: 80px 0 60px;
		letter-spacing: -0.49px;
	}
	
	h3 {
		font-size: 26px;
		line-height: 1.65;
		margin-bottom: 40px;
	}
	
	p {
		font-size: 26px;
		margin-bottom: 60px;
		letter-spacing: 1px;
	}
}

// About Section - End

// skill section - Start

.progress_item {
	position: relative;
	margin-bottom: 50px;
}

.progress_item {
	.item_title {
		color: #222222;
		line-height: 1;
		font-size: 26px;
		font-weight: 600;
	}

	.progress {
		width: 100%;
		height: 2px;
		margin-top: 15px;
		border-radius: 0;
		overflow: visible;
		background-color: #F5F5F5;
	}
	.progress_bar {
		height: 6px;
		margin-top: -2px;
		position: relative;
		background-color: $color_primary;
	}
	.value_text {
		z-index: 1;
		top: -44px;
		right: -5%;
		font-size: 26px;
		position: absolute;
		color: $color_primary;
	}
}

.Rx_width_60 {
	-webkit-animation-name: Rx_width_60;
	animation-name: Rx_width_60;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_60 {
	0% {
		width: 0%;
	}
	100% {
		width: 60%;
	}
}

.Rx_width_75 {
	-webkit-animation-name: Rx_width_75;
	animation-name: Rx_width_75;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_75 {
	0% {
		width: 0%;
	}
	100% {
		width: 75%;
	}
}

.Rx_width_80 {
	-webkit-animation-name: Rx_width_80;
	animation-name: Rx_width_80;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_80 {
	0% {
		width: 0%;
	}
	100% {
		width: 80%;
	}
}

.Rx_width_82 {
	-webkit-animation-name: Rx_width_82;
	animation-name: Rx_width_82;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_82 {
	0% {
		width: 0%;
	}
	100% {
		width: 82%;
	}
}

.Rx_width_84 {
	-webkit-animation-name: Rx_width_84;
	animation-name: Rx_width_84;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_84 {
	0% {
		width: 0%;
	}
	100% {
		width: 84%;
	}
}

.Rx_width_88 {
	-webkit-animation-name: Rx_width_88;
	animation-name: Rx_width_88;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_88 {
	0% {
		width: 0%;
	}
	100% {
		width: 88%;
	}
}

.Rx_width_90 {
	-webkit-animation-name: Rx_width_90;
	animation-name: Rx_width_90;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_90 {
	0% {
		width: 0%;
	}
	100% {
		width: 90%;
	}
}

.Rx_width_92 {
	-webkit-animation-name: Rx_width_92;
	animation-name: Rx_width_92;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_92 {
	0% {
		width: 0%;
	}
	100% {
		width: 92%;
	}
}

.Rx_width_94 {
	-webkit-animation-name: Rx_width_94;
	animation-name: Rx_width_94;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_94 {
	0% {
		width: 0%;
	}
	100% {
		width: 94%;
	}
}

.Rx_width_96 {
	-webkit-animation-name: Rx_width_96;
	animation-name: Rx_width_96;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_96 {
	0% {
		width: 0%;
	}
	100% {
		width: 96%;
	}
}

.Rx_width_98 {
	-webkit-animation-name: Rx_width_98;
	animation-name: Rx_width_98;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@keyframes Rx_width_98 {
	0% {
		width: 0%;
	}
	100% {
		width: 98%;
	}
}

// skill section - End

// About Section 2 - Start

.about_section_2 {
	.row {
		margin: -30px;
		.col {
			padding: 30px;
		}
	}
	
	h2 {
		font-size: 56px;
		margin-bottom: 30px;
		letter-spacing: -0.02em;
	}
	
	p {
		color: #818291;
		font-size: 20px;
		padding-right: 55px;
		margin-bottom: 40px;
		strong {
			color: #0E0E0E;
			display: block;
			font-weight: 500;
		}
	}
	
	.image_wrap {
		margin-bottom: 90px;
	}
}

// About Section 2 - End

// About Section 3 - Start
.about_section_3 {
	overflow: visible;
	.about_content {
		padding-right: 75px;
	}
	h2 {
		color: #ffffff;
		font-size: 72px;
		margin-bottom: 40px;
		letter-spacing: -0.06em;
		strong {
			font-weight: 700;
		}
		span {
			display: block;
			font-size: 36px;
			margin-bottom: 15px;
			letter-spacing: -0.03em;
		}
	}

	p {
		color: #bbbbbb;
		font-size: 26px;
		letter-spacing: 0.04em;
	}

	.circle_img_1 {
		top: -60px;
		right: 507px;
		max-width: 176px;
	}
	
	.circle_img_2 {
		top: -128px;
		right: -276px;
		max-width: 728px;
	}
}
// About Section 3 - End

// About Section 4 - Start
.about_section_4 {
	h2,
	h3 {
		color: #d0d0d0;
		font-size: 72px;
		margin-bottom: 40px;
		letter-spacing: -0.04em;
	}

	span {
		display: block;
		line-height: 1;
		color: #d0d0d0;
		font-size: 36px;
		margin-bottom: 30px;
		letter-spacing: -0.03em;
	}

	p {
		color: #dddddd;
		font-size: 26px;
		letter-spacing: 0.04em;
	}

	img {
		margin: 100px 0;
	}

	hr {
		margin: 65px 0;
		background-color: #707070;
	}
}
// About Section 4 - End

// About Section 5 - Start
.about_section_5 {
	.about_image {
		padding: 10px;
		margin-bottom: 90px;
		background-color: #ffffff;
		box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.1);
	}

	p {
		letter-spacing: 0.02em;
	}
}
// About Section 5 - End

// About Section 6 - Start
.about_section_6 {
	z-index: 1;
	position: relative;

	.big_text {
		left: 0;
		right: 0;
		z-index: -1;
		bottom: -130px;
		line-height: 1;
		color: #f2f2f2;
		font-size: 262px;
		font-weight: 700;
		position: absolute;
		letter-spacing: -0.03em;
	}

	.about_image {
		padding: 10px;
		background-color: #ffffff;
		box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.1);
	}

	.about_content {
		padding-left: 30px;
	}

	.title_text {
		line-height: 1;
		font-size: 78px;
		margin-bottom: 20px;
		letter-spacing: -0.06em;
	}

	p {
		font-size: 26px;
		margin-bottom: 30px;
		letter-spacing: 0.02em;
	}
}
// About Section 6 - End

// Skill Item - Start
.skill_item {
	.value_text {
		display: block;
		line-height: 1;
		font-size: 72px;
		position: static;
		color: $color_black;
	}

	.progress {
		height: 5px;
		margin-bottom: 20px;
		background-color: rgba(95, 95, 95, 0.1);
	}

	.progress_bar {
		height: 5px;
		margin-top: 0;
		background-color: $color_danger;
	}

	.item_title {
		line-height: 1;
		font-size: 30px;
		margin-bottom: 5px;
	}

	.designation {
		color: #828282;
		display: block;
		line-height: 1;
		font-size: 18px;
	}
}
// Skill Item - End

// About Section 7 - Start
.about_section_7 {
	z-index: 1;
	position: relative;

	.focus_text {
		left: 0;
		right: 0;
		z-index: -1;
		bottom: -60px;
		color: #F5F5F5;
		font-weight: 800;
		font-size: 232px;
		position: absolute;
	}

	p {
		color: #5a5c62;
		font-size: 18px;
		line-height: 32px;
		margin-bottom: 30px;
		letter-spacing: 0.03em;
		strong {
			color: #2a2b2f;
			font-size: 26px;
			line-height: 36px;
		}
	}

	.mission_content {
		&:not(:last-child) {
			margin-bottom: 100px;
		}

		.item_title {
			font-size: 36px;
			font-weight: 700;
			line-height: 46px;
			margin-bottom: 15px;
		}
	}

	.photography_tab_wrap {
		position: relative;
		padding: 65px 0 0 65px;

		.tab-pane {
			background-color: #ffffff;
			img {
				opacity: 0.2;
				transition: $transition;
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.photography_tabs_nav {
		top: 0;
		left: 0;
		z-index: 1;
		position: absolute;
		button	{
			color: #2b2c30;
			font-size: 18px;
			line-height: 52px;
			letter-spacing: 0.03em;
			&:hover,
			&.active {
				color: $color_danger;
			}
		}
	}
}
// About Section 7 - End

/* 3.8 - About - Start
================================================== */