/* 3.15 - Contact - Start
================================================== */

// Conatct Section - Start
.contact_section {
	&.bg_gray {
		background-color: #F9F9F9;
	}
}
.map_section {
	iframe {
		width: 100%;
		height: 600px;
	}
}
// Conatct Section - End

// Conatct 1 - Start
.contact_form {
	padding-top: 30px;
	
	.row {
		margin: 0 -15px;
	}
	.col {
		padding: 0 15px;
	}

	.btn {
		margin-top: 30px;
	}

	&.from_gray {
		.select_option {
			.nice-select {
				background-color: #f9f9f9;
				.list {
					background-color: #f9f9f9;
				}
			}
		}
		.form_item {
			input,
			textarea {
				background-color: #f9f9f9;
			}
		}
	}
}

.contact_info_list {
	h3 {
		line-height: 1;
		font-size: 36px;
		margin-bottom: 20px;
	}
	a {
		color: $color_black;
		&:hover {
			color: $color_primary;
		}
	}
}

.contact_info_list_2 {
	li {
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	span {
		height: 60px;
		flex: 0 0 60px;
		margin-right: 30px;
		align-items: center;
		border-radius: 100%;
		display: inline-flex;
		justify-content: center;
		border: 1px solid $color_primary;
	}
	a {
		color: #818291;
		font-size: 20px;
		&:hover {
			color: $color_primary;
			text-decoration: underline;
		}
	}
}
// Conatct 1 - End

// Conatct 2 - Start
.contact_area_2 {
	padding-top: 90px;
	h2 {
		font-size: 56px;
		margin-bottom: 35px;
		letter-spacing: -0.02em;
	}
	p {
		font-size: 20px;
	}
	.contact_content {
		padding-right: 60px;
	}

	.select_option {
		.nice-select {
			height: 70px;
			line-height: 66px;
			border: 2px solid #818181;
		}
	}
	.form_item {
		input,textarea {
			border: 2px solid #818181;
		}
		input {
			height: 70px;
		}
	}
}
// Conatct 2 - End

// Conatct 3 - Start
.contact_info_wrap_2 {
	.section_title_3 {
		margin-bottom: 62px;
	}
	p {
		color: #818291;
		font-size: 20px;
		margin-bottom: 30px;
	}
}
// Conatct 3 - End

// Conatct 4 - Start
.contact_form_2 {
	padding: 150px 0 120px;
	h2 {
		color: #d0d0d0;
		font-size: 72px;
		text-align: center;
		margin-bottom: 70px;
		letter-spacing: -0.04em;
	}
	.select_option .nice-select {
		background-color: transparent;
		border: 1px solid rgba(255, 255, 255, 0.2);
		&:hover {
			border-color: #ffffff;
		}
	}
	.form_item {
		textarea,input {
			color: #ffffff;
			background-color: transparent;
			border: 1px solid rgba(255, 255, 255, 0.2);
			&:focus {
				border-color: #ffffff;
			}

			&:-moz-placeholder {
				color: #ffffff;
			}
			&::-moz-placeholder {
				color: #ffffff;
			}
			&:-ms-input-placeholder {
				color: #ffffff;
			}
			&::-webkit-input-placeholder {
				color: #ffffff;
			}
		}
	}
}
// Conatct 4 - End

// Conatct 5 - Start
.contact_icon_box {
	display: flex;
	align-items: center;
	background-color: #ffffff;
	border: 1px solid #DDDDDD;
	.item_icon {
		width: 112px;
		height: 112px;
		font-size: 30px;
		margin-right: 30px;
		align-items: center;
		color: $color_danger;
		display: inline-flex;
		justify-content: center;
		background-color: #FFEDED;
		border: 1px solid $color_danger;
	}
	.item_content {
		color: #000;
		line-height: 1;
		font-size: 26px;
		font-weight: 500;
		letter-spacing: -0.02em;
	}
}
// Conatct 5 - End

// Dark Contact Section - Start
.dark_contact_section {
	padding: 190px 0 100px;
	background-color: #15161B;

	.select_option .nice-select {
		color: #ffffff;
		padding: 0 40px;
		border-radius: 50px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		background-color: rgba(255, 255, 255, 0.05);
		&:hover {
			border-color: #ffffff;
		}
	}

	.form_item input {
		padding: 0 40px;
		border-radius: 50px;
	}
	.form_item textarea {
		padding: 30px 40px;
		border-radius: 34px;
	}
	.form_item input,
	.form_item textarea {
		color: #ffffff;
		border: 1px solid rgba(255, 255, 255, 0.2);
		background-color: rgba(255, 255, 255, 0.05);
		&:hover,
		&:focus {
			border-color: #ffffff;
		}
		&:-moz-placeholder {
			color: #ffffff;
		}
		&::-moz-placeholder {
			color: #ffffff;
		}
		&:-ms-input-placeholder {
			color: #ffffff;
		}
		&::-webkit-input-placeholder {
			color: #ffffff;
		}
	}
}

.contact_line_2 {
	a {
		color: #ffffff;
		&:hover {
			color: $color_primary;
			text-decoration: underline;
		}
	}
	span {
		font-size: 26px;
		font-weight: 200;
	}
	strong {
		font-size: 36px;
		font-weight: 600;
		a {
			color: $color_danger;
		}
	}
}

.instagram_shoot_wrap {
	ul {
		margin: 0 -15px;
		>li {
			padding: 15px;
		}
	}

	ul {
		&:nth-child(1) {
			align-items: flex-end;
			>li {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(5) {
					a {
						max-width: 200px;
					}
				}

				&:nth-child(3) {
					a {
						max-width: 320px;
					}
				}
				
				&:nth-child(4) {
					a {
						max-width: 260px;
					}
				}
			}
		}
	}

	ul {
		&:nth-child(2) {
			align-items: flex-start;
			>li {
				&:nth-child(1),
				&:nth-child(3),
				&:nth-child(5) {
					a {
						max-width: 200px;
					}
				}

				&:nth-child(2),
				&:nth-child(4) {
					a {
						max-width: 260px;
					}
				}
			}
		}
	}
}
// Dark Contact Section - End

/* 3.15 - Contact - End
================================================== */