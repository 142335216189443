/* 2.13 - Category List - Start
================================================== */
.category_list {
  >li {
    position: relative;
    &:not(:last-child) {
      margin-right: 5px;
      padding-right: 3px;
    }
    &:after {
      right: 0;
      bottom: 0;
      content: ',';
      line-height: 1;
      position: absolute;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  a {
    display: block;
    color: #818291;
    line-height: 1;
    &:hover {
      color: $color_primary;
    }
  }
}
/* 2.13 - Category List - End
================================================== */