/* 3.19 - Video - Start
================================================== */
.video_section {
	z-index: 1;
	display: flex;
	min-height: 960px;
	position: relative;
	align-items: center;
	justify-content: center;
	@include bg_image_property;
	.ovarlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		position: absolute;
		background-color: rgba(13, 13, 68, 0.8);
		&.bg_dark {
			background-color: rgba(25, 25, 25, 0.8);
		}
	}
}

.intro_video {
	position: relative;
	&:before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		content: '';
		position: absolute;
		background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent);
	}
	.play_btn_2 {
		left: 50px;
		z-index: 2;
		bottom: 50px;
		position: absolute;
	}
}
/* 3.19 - Video - End
================================================== */