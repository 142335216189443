/* 2.8 - Form - Start
================================================== */
.form_item {
	position: relative;
	margin-bottom: 30px;
	textarea,input {
		width: 100%;
		outline: none;
		display: block;
		box-shadow: none;
		border-radius: 0;
		letter-spacing: .27px;
		border: 1px solid #D8D8D8;
		transition: $transition;
		&:focus {
			outline: none;
			box-shadow: none;
			border-color: $color_primary;
		}
	}
	input {
		height: 100px;
		padding: 0 30px;
	}
	textarea {
		min-height: 200px;
		padding: 25px 30px;
	}
}

:-moz-placeholder {
	color: #272727;
	letter-spacing: .27px;
}
::-moz-placeholder {
	color: #272727;
	letter-spacing: .27px;
}
:-ms-input-placeholder {
	color: #272727;
	letter-spacing: .27px;
}
::-webkit-input-placeholder {
	color: #272727;
	letter-spacing: .27px;
}

.select_option {
	position: relative;
	margin-bottom: 30px;
	.nice-select {
		width: 100%;
		height: 100px;
		font-size: 18px;
		padding: 0 30px;
		border-radius: 0;
		line-height: 100px;
		position: relative;
		transition: $transition;
		border: 1px solid #D8D8D8;
		&:hover {
			border-color: $color_primary;
		}
		&:after {
			margin: 0;
			width: auto;
			height: auto;
			border: none;
			float: right;
			margin-left: 5px;
			font-weight: 400;
			content: '\f107';
			transform: unset;
			position: static;
			transition: $transition;
			font-family: 'Font Awesome 5 Pro';
		}

		.list {
			padding: 0;
			width: 100%;
			margin-top: 0;
			border-radius: 0;
			min-width: 220px;
			box-shadow: none;
			border: 1px solid #D8D8D8;
		}

		.option {
			line-height: 1;
			min-height: auto;
			padding: 20px 25px;
			transition: $transition;
			color: rgba(39, 39, 39, 0.6);
		}

		.option:hover,
		.option.focus,
		.option.selected.focus {
			font-weight: 400;
			color: $color_black;
		}
	}
}
/* 2.8 - Form - End
================================================== */