/* ================================================== 
* Project Name   :  Dexfolio - Portfolio & Agency Template
* File           :  CSS Base
* Version        :  1.0.0
* Last change    :  11 April 2022
* Developer		 :  Tortoiz Themes
*	css code for responsive layout To make Responsive
================================================== */

/* media screen and (max-width: 1460px) - start
================================================== */
@media screen and (max-width: 1460px) {

	.header_style_2,
	.header_style_5 {
		.main_menu {
			padding: 25px 15px;
		}
		&.sticky {
			.main_menu {
				padding: 15px;
			}
		}
	}
	.main_menu_list > li:not(:last-child) {
		margin-right: 40px;
	}

	.header_style_3 {
		padding: 30px 0;
	}
	.header_style_3.sticky {
		padding: 15px 0;
	}

	.mouse_wheel_slider .slider_item .item_content {
		padding: 20px;
	}
	.mouse_wheel_slider .slider_item .item_title {
		font-size: 28px;
		line-height: 32px;
	}
	.mouse_wheel_slider .slider_item .subtitle {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 10px;
	}
	.mouse_wheel_slider .slider_item p {
		font-size: 16px;
		line-height: 28px;
	}
	.slider_section .social_primary > li:not(:last-child) {
		margin: 0 0 10px;
	}
	.slider_section .go_down_btn span {
		font-size: 12px;
		line-height: 16px;
	}
	.slider_section .carousel_arrow button {
		width: 50px;
		height: 50px;
		font-size: 24px;
		margin: 0 0 10px;
	}
	.slider_section .slick-dots li:not(:last-child) {
		margin: 0;
	}
	.slider_section .slick-dots li button {
		width: 10px;
		height: 10px;
	}
	.slider_section .carousel_arrow {
		top: 180px;
		right: 30px;
	}
	.slider_section .slick-dots {
		right: 15px;
		margin-top: 100px;
	}
	.header_style_4 .offcanvas_btn_2 {
		width: 50px;
		height: 50px;
		svg {
			max-width: 20px;
		}
	}
	.header_style_4 {
		padding: 20px 30px;
	}
	.header_style_4.sticky {
		padding: 15px 30px;
	}
	.slider_section {
		padding-left: 100px;
	}
	.slider_section .social_area {
		width: 100px;
		padding-top: 20px;
	}

	.portfolio_list_layout .item_content {
		padding: 80px 100px;
	}

	.spacer_2 {
		height: 130px;
	}

	.breadcrumb_style_4 .big_text {
		left: 0;
		bottom: -115px;
		font-size: 460px;
	}
	.about_section_6 .big_text {
		bottom: -122px;
		font-size: 230px;
	}

	.other_post_item .item_content {
		padding: 60px;
	}
	.other_post_item h3 {
		font-size: 72px;
		margin-bottom: 50px;
		letter-spacing: -1px;
	}

}
/* media screen and (max-width: 1460px) - end
================================================== */

/* media screen and (max-width: 1360px) - start
================================================== */
@media screen and (max-width: 1360px) {

	.banner_style_2 .circle_shape_2,
	.banner_style_3 .circle_shape_2 {
		left: 20px;
		max-width: 100px;
	}

	.portfolio_grid_layout .item_content,
	.portfolio_grid_layout .item_date {
		padding: 30px;
	}
	.testimonial_item_4 p {
		font-size: 30px;
		line-height: 42px;
		margin-bottom: 40px;
	}

	.other_post_item .item_image {
		flex: 0 0 50%;
	}
	.other_post_item h3 {
		font-size: 54px;
		margin-bottom: 30px;
	}

}
/* media screen and (max-width: 1360px) - end
================================================== */

/* media screen and (max-width: 1199px) - start
================================================== */
@media screen and (max-width: 1199px) {

	body {
		font-size: 18px;
	}

	.header_style_1,
	.header_style_1.sticky {
		padding: 20px 15px;
	}

	.banner_section {
		min-height: 100vh;
		padding: 150px 0 100px;
	}
	.banner_style_1 h1 {
		font-size: 72px;
	}
	.banner_style_1 p {
		font-size: 22px;
	}

	.btn,
	.btn.btn_shadow {
		font-size: 16px;
		min-width: 230px;
		padding: 27px 35px;
	}

	.section_title_2 .title_text {
		font-size: 72px;
		margin-bottom: 15px;
	}
	.section_title_2 p {
		font-size: 22px;
	}

	.portfolio_card_layout .item_title {
		font-size: 30px;
		margin-bottom: 6px;
	}
	.portfolio_card_layout .item_category a {
		font-size: 16px;
	}

	.masonry_portfolio {
		margin: -15px;
	}
	.masonry_portfolio .element-item {
		padding: 15px;
	}

	.container {
		padding-left: 15px;
		padding-right: 15px;
	}
	.row {
		margin: -15px;
	}
	.col {
		padding: 15px;
	}

	.service_grid .item_title {
		font-size: 30px;
	}
	.service_grid .item_image {
		margin-bottom: 20px;
	}

	.award_item .year_text {
		font-size: 54px;
		margin-bottom: 15px;
	}
	.award_item .award_list a {
		font-size: 22px;
	}

	.contact_form .btn {
		margin-top: 0;
	}
	.select_option .nice-select {
		height: 70px;
		line-height: 70px;
	}
	.form_item input {
		height: 70px;
	}

	.banner_style_2 .dot_shape_1, .banner_style_2 .dot_shape_2, .banner_style_3 .dot_shape_1, .banner_style_3 .dot_shape_2 {
		max-width: 120px;
	}
	.banner_style_2 .deco_title_1, .banner_style_3 .deco_title_1 {
		right: 0;
		bottom: -90px;
	}
	.banner_style_2 .deco_title_1, .banner_style_2 .deco_title_2, .banner_style_3 .deco_title_1, .banner_style_3 .deco_title_2 {
		font-size: 230px;
	}
	.banner_style_2 .circle_shape, .banner_style_3 .circle_shape {
		right: 180px;
		bottom: 120px;
		max-width: 150px;
	}
	.banner_style_2 .triangle_shape, .banner_style_3 .triangle_shape {
		display: none;
	}

	.main_menu_list > li > a {
		font-size: 16px;
	}

	.portfolio_fullimage .item_content {
		left: 15px;
		right: 15px;
		bottom: 15px;
		max-width: 100%;
	}
	.portfolio_fullimage h3 {
		font-size: 24px;
		margin-bottom: 10px;
	}

	.primary_section_title {
		font-size: 42px;
		margin-bottom: 40px;
	}
	.testimonial_item_2 p {
		font-size: 24px;
		font-weight: 600;
	}
	.testimonial_item_2 h3 {
		font-size: 16px;
	}
	.rating_star li {
		font-size: 14px;
	}
	.slick-dots li button {
		width: 15px;
		height: 15px;
	}

	.contact_area_2 h2 {
		font-size: 42px;
		margin-bottom: 30px;
	}

	.banner_style_2 .dot_shape_1, .banner_style_2 .dot_shape_2, .banner_style_3 .dot_shape_1, .banner_style_3 .dot_shape_2,
	.banner_style_2 .circle_shape_2, .banner_style_3 .circle_shape_2 {
		display: none;
	}

	.header_style_3,
	.header_style_3.sticky {
		padding: 15px 0;
	}
	.header_contact_info > li {
		font-size: 15px;
	}
	.header_contact_info > li:not(:last-child):after {
		margin: 0 20px;
	}
	.banner_style_4 h1 {
		font-size: 120px;
		margin-bottom: 40px;
	}
	.banner_style_4 p {
		font-size: 20px;
		margin-bottom: 60px;
	}

	.play_btn_2 span {
		width: 70px;
		height: 70px;
		svg {
			max-width: 24px;
		}
	}
	.play_btn_2 small {
		font-size: 20px;
		margin-left: 20px;
	}
	.banner_style_4 .social_primary a {
		font-size: 15px;
	}
	.about_section_3 h2 {
		font-size: 60px;
		margin-bottom: 30px;
	}
	.about_section_3 p {
		font-size: 22px;
		letter-spacing: 0;
	}
	.about_section_3 h2 span {
		font-size: 30px;
		letter-spacing: 0;
	}
	.about_section_3 .circle_img_2 {
		right: -300px;
		max-width: 600px;
	}
	.about_section_3 .circle_img_1 {
		top: -80px;
		right: 380px;
		max-width: 160px;
	}
	.testimonial_item_1.style_2 p {
		font-size: 42px;
		line-height: 1.3;
		letter-spacing: 0;
	}
	.testimonial_item_1.style_2 .quote_icon {
		margin-bottom: 40px;
	}
	.footer_style_3 .contact_info_list li:nth-child(2) {
		font-size: 54px;
	}

	.banner_style_5 h1 {
		line-height: 1;
		font-size: 100px;
		letter-spacing: 0;
	}
	.banner_style_5 .big_text {
		bottom: -80px;
		font-size: 350px;
	}

	.section_title_2 .focus_text {
		font-size: 120px;
	}

	.skill_item .value_text {
		font-size: 54px;
	}
	.skill_item .item_title {
		font-size: 22px;
	}

	.portfolio_list_layout_2 .item_title {
		font-size: 54px;
	}
	.portfolio_list_layout_2 p {
		font-size: 20px;
		letter-spacing: 0;
	}
	.portfolio_list_layout_2 .btn_text {
		font-size: 22px;
		letter-spacing: 0;
	}

	.portfolio_list_layout_2 .item_category > li {
		font-size: 24px;
		letter-spacing: 0;
	}

	.calltoaction_section_3 {
		min-height: auto;
	}
	.calltoaction_section_3 h2 {
		font-size: 66px;
		letter-spacing: -1px;
	}

	.footer_style_4 .contact_line {
		font-size: 84px;
		line-height: 1.2;
		letter-spacing: -1px;
	}

	.slider_section .carousel_arrow {
		display: none;
	}
	.slider_section .slick-dots {
		margin-top: 0;
	}
	.mouse_wheel_slider .slider_item p {
		margin-bottom: -95px;
	}

	.about_section_7 p {
		font-size: 16px;
		line-height: 28px;
		strong {
			font-size: 22px;
		}
	}
	.about_section_7 .focus_text {
		bottom: -26px;
		font-size: 190px;
	}
	.section_title_4 .title_text {
		font-size: 78px;
	}
	.section_space_rl {
		padding-left: 15px;
		padding-right: 15px;
	}
	.testimonial_item_4 .admin_name {
		line-height: 1;
		font-size: 22px;
	}
	.testimonial_item_4 p {
		font-size: 24px;
		line-height: 36px;
	}
	.blog_item_boxed_2 .item_title {
		font-size: 30px;
		line-height: 42px;
	}
	.blog_item_boxed_2 .post_meta {
		margin-bottom: 15px;
	}
	.footer_style_4 .contact_line {
		font-size: 66px;
	}
	.footer_style_4 .footer_widget_area {
		padding: 80px 0;
	}
	.footer_style_4 .footer_bottom {
		padding: 25px 0;
	}

	.video_section {
		min-height: 800px;
	}

	.breadcrumb_style_1 {
		padding: 120px 0 80px;
		align-items: flex-end;
	}
	.breadcrumb_style_1 h1 {
		font-size: 140px;
		margin-bottom: 20px;
	}

	.portfolio_details .details_image {
		min-height: 770px;
	}
	.portfolio_details h1 {
		font-size: 72px;
	}
	.portfolio_details h2 {
		font-size: 66px;
		margin-bottom: 30px;
		letter-spacing: -2px;
	}
	.portfolio_details .details_content p {
		font-size: 22px;
		letter-spacing: 0;
		margin-bottom: 40px;
	}
	.portfolio_details h3 {
		font-size: 30px;
	}

	.other_post_item .item_image {
		flex: 0 0 50%;
	}
	.other_post_item .item_content {
		padding: 60px;
	}
	.other_post_item h3 {
		font-size: 66px;
		margin-bottom: 50px;
		letter-spacing: -2px;
	}
	.other_post_item h4 {
		font-size: 26px;
	}

	.breadcrumb_style_2 {
		padding: 220px 0 0;
	}
	.about_section_2 .image_wrap {
		margin-bottom: 60px;
	}
	.team_item_2 .team_image {
		margin-bottom: 25px;
	}
	.team_item_2 .team_name {
		font-size: 24px;
		margin-bottom: 8px;
	}
	.team_item_2 .team_title {
		font-size: 15px;
	}
	.calltoaction_style_2 {
		padding: 140px 0;
	}

	.section_space_lg {
		padding-top: 120px;
		padding-bottom: 120px;
	}
	.footer_style_2 .footer_bottom {
		padding-bottom: 25px;
	}

	.portfolio_list_layout .item_content {
		padding: 60px;
	}

	.breadcrumb_style_2.pb_space {
		padding: 220px 0 80px;
	}
	.filter-btns-group button {
		font-size: 20px;
	}
	.filter-btns-group li:not(:last-child) {
		margin-right: 30px;
	}

	.blog_details.style_2 .blog_details_content p {
		font-size: 18px;
	}
	.blog_details .blog_details_content p {
		margin-bottom: 35px;
	}

	.contact_section  .section_title_3,
	.contact_info_wrap_2 .section_title_3 {
		margin-bottom: 30px;
	}
	.section_title_3 .title_text {
		font-size: 48px;
		margin-bottom: 0;
		letter-spacing: 0;
	}
	.section_space {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.breadcrumb_style_3 {
		padding-top: 230px;
	}

	.about_section_4 h2, .about_section_4 h3 {
		font-size: 60px;
		line-height: 1.2;
		letter-spacing: 0;
		margin-bottom: 30px;
	}
	.about_section_4 p {
		font-size: 22px;
		letter-spacing: 0;
	}
	.about_section_4 span {
		font-size: 30px;
		letter-spacing: 0;
		margin-bottom: 10px;
	}
	.award_item.style_2 p {
		font-size: 28px;
		letter-spacing: 0;
	}
	.award_item.style_2 .year_text {
		font-size: 32px;
		letter-spacing: 0;
	}

	.breadcrumb_style_4 .big_text {
		bottom: -100px;
		font-size: 400px;
	}
	.breadcrumb_style_4 {
		min-height: 500px;
	}

	.about_section_6 .about_content {
		padding: 0;
	}
	.section_title_2 .title_text {
		font-size: 54px;
	}
	.section_title_2 p {
		font-size: 20px;
	}

	.testimonial_item_3 p {
		font-size: 38px;
	}

	.video_section {
		min-height: 600px;
	}
	.play_btn {
		width: 100px;
		height: 100px;
	}

	.blog_grid .item_title {
		font-size: 22px;
		margin-bottom: 20px;
	}
	.blog_grid .post_date {
		font-size: 16px;
		margin-bottom: 15px;
	}
	.blog_grid .btn_text {
		font-size: 16px;
	}

	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}

	.portfolio_list_layout .item_category {
		margin-bottom: 10px;
	}
	.portfolio_list_layout .item_category > li {
		font-size: 16px;
	}
	.portfolio_list_layout .item_content {
		padding: 40px;
	}
	.portfolio_split_wrap .portfolio_list_layout .item_title {
		font-size: 30px;
		margin-bottom: 15px;
	}
	.portfolio_list_layout p {
		font-size: 18px;
		margin-bottom: 30px;
	}

	.instagram_shoot_wrap ul:nth-child(1) > li:nth-child(1) a,
	.instagram_shoot_wrap ul:nth-child(1) > li:nth-child(2) a,
	.instagram_shoot_wrap ul:nth-child(1) > li:nth-child(5) a,
	.instagram_shoot_wrap ul:nth-child(2) > li:nth-child(1) a,
	.instagram_shoot_wrap ul:nth-child(2) > li:nth-child(3) a,
	.instagram_shoot_wrap ul:nth-child(2) > li:nth-child(5) a {
		max-width: 160px;
	}
	.instagram_shoot_wrap ul:nth-child(1) > li:nth-child(4) a,
	.instagram_shoot_wrap ul:nth-child(2) > li:nth-child(2) a,
	.instagram_shoot_wrap ul:nth-child(2) > li:nth-child(4) a {
		max-width: 230px;
	}
	.instagram_shoot_wrap ul:nth-child(1) > li:nth-child(3) a {
		max-width: 260px;
	}

}
/* media screen and (max-width: 1199px) - end
================================================== */

/* media screen and (max-width: 1024px) - start
================================================== */
@media screen and (max-width: 1024px) {

	.banner_style_2 h1, .banner_style_3 h1 {
		font-size: 154px;
	}
	.banner_style_2 .circle_shape, .banner_style_3 .circle_shape {
		right: 100px;
		bottom: 170px;
		max-width: 90px;
	}
	.banner_style_2 .dot_shape_2, .banner_style_3 .dot_shape_2 {
		right: 100px;
		bottom: 162px;
	}

	.progress_item .item_title,
	.progress_item .value_text {
		font-size: 20px;
	}
	.progress_item .value_text {
		top: -40px;
	}

	.feature_section h2 {
		font-size: 48px;
		margin-bottom: 25px;
	}
	.feature_section p {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.offcanvas_btn_2 {
		width: 60px;
		height: 60px;
		svg {
			max-width: 22px;
		}
	}
	.main_menu_list > li > a {
		font-size: 14px;
		padding: 10px 0;
	}
	.main_menu_list .dropdown > a:after {
		font-size: 14px;
		margin-left: 4px;
	}
	.main_menu_list > li:not(:last-child) {
		margin-right: 25px;
	}
	.header_style_2 .brand_logo {
		max-width: 120px;
	}

	.line_decoration_y .line_item:nth-child(1) {
		left: 15px;
		width: 90px;
	}
	.line_decoration_y .line_item:nth-child(3) {
		width: 90px;
		right: 15px;
	}
	.contact_area_2 .contact_content {
		padding-right: 0;
	}

	.client_style_3 .client_item {
		height: 120px;
		padding: 20px;
	}

	.testimonial_item_1.style_2 p {
		font-size: 36px;
	}
	.testimonial_tab .nav {
		width: auto;
		max-width: 467px;
	}
	.testimonial_tab .nav li:nth-child(3) {
		left: 30px;
	}

	.portfolio_list_layout_2:nth-child(odd) .item_content,
	.portfolio_list_layout_2:nth-child(even) .item_content {
		padding: 0 30px;
	}

	.service_item_icon .item_title {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.service_item_icon p {
		font-size: 16px;
	}
	.service_item_icon .item_icon {
		width: 140px;
		height: 140px;
		svg {
			max-height: 50px;
		}
	}
	.service_item_icon .icon_wrap {
		width: 110px;
		height: 110px;
	}

	.section_title .title_text {
		font-size: 120px;
		margin-bottom: 15px;
		letter-spacing: -8px;
	}
	.about_section p {
		font-size: 22px;
		letter-spacing: 0;
		margin-bottom: 40px;
	}
	.section_title {
		margin-bottom: 60px;
	}
	.spacer {
		height: 120px;
	}

	.about_section h2 {
		font-size: 78px;
		margin: 40px 0 45px;
	}
	.about_section h3 {
		font-size: 22px;
		margin-bottom: 30px;
	}

	.calltoaction_section h2 {
		font-size: 22px;
	}
	.calltoaction_section h3 {
		font-size: 60px;
	}

	.video_section {
		min-height: 600px;
	}
	.play_btn {
		width: 120px;
		height: 120px;
	}
	.testimonial_item_1 p {
		font-size: 42px;
		margin-bottom: 40px;
	}

	.section_title .title_text {
		margin-bottom: 25px;
	}
	.section_title p {
		font-size: 24px;
	}

	.blog_fullwidth .item_title {
		font-size: 48px;
	}
	.blog_standard .item_title {
		font-size: 30px;
	}
	.post_meta .admin_thumbnail {
		margin-right: 15px;
	}
	.post_meta svg, .post_meta i {
		margin-right: 15px;
	}
	.btn_text {
		font-size: 16px;
		letter-spacing: 0;
	}
	.pagination_nav a {
		width: 70px;
		height: 70px;
		font-size: 18px;
	}
	.sidebar_section {
		padding: 25px;
	}

	.blog_details .blog_details_content p {
		font-size: 24px;
		letter-spacing: 0;
		margin-bottom: 35px;
	}
	.blog_item_boxed p {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.blog_item_boxed {
		padding: 40px 15px;
	}

	.breadcrumb_style_2 h1 {
		font-size: 100px;
		margin-bottom: 35px;
	}
	.breadcrumb_style_2 p {
		font-size: 22px;
	}
	.about_section_2 p {
		font-size: 18px;
		padding-right: 0;
		margin-bottom: 30px;
	}
	.calltoaction_section .mail_text {
		font-size: 42px;
	}
	.calltoaction_style_2 {
		padding: 120px 0;
	}
	.footer_style_2 .footer_widget_title {
		font-size: 28px;
		margin-bottom: 25px;
	}

	.feature_section h2 {
		font-size: 42px;
		line-height: 1.2;
	}

	.service_primary {
		padding-right: 0;
		p {
			font-size: 18px;
		}
	}
	.service_primary .item_title {
		font-size: 28px;
		letter-spacing: 0;
		margin-bottom: 20px;
	}
	.service_boxed {
		padding: 50px 30px;
		align-items: flex-start;
	}
	.service_boxed p {
		font-size: 18px;
	}
	.service_boxed .item_title {
		font-size: 30px;
		letter-spacing: 0;
	}

	.section_title_3 .title_text {
		font-size: 48px;
		letter-spacing: 0;
	}
	.section_title_3 p {
		font-size: 18px;
	}
	.service_boxed .item_icon {
		flex: 0 0 120px;
	}

	.double_portfolio_wrap:not(:last-child) {
		margin-bottom: 50px;
	}

	.portfolio_list_layout p {
		font-size: 18px;
		margin-bottom: 30px;
	}
	.portfolio_list_layout .item_title {
		font-size: 26px;
		letter-spacing: 0;
		margin-bottom: 20px;
	}
	.portfolio_list_layout .item_content {
		padding: 30px;
	}

	.calltoaction_boxed {
		padding: 80px;
	}
	.calltoaction_boxed p {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.calltoaction_boxed .mail_text {
		font-size: 42px;
	}

	.blog_grid .item_content {
		padding: 25px;
	}
	.blog_grid .item_title {
		font-size: 20px;
		line-height: 1.2;
		letter-spacing: 0;
		margin-bottom: 15px;
	}
	.blog_grid .post_date {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.blog_grid .btn_text {
		font-size: 16px;
	}
	.load_more {
		margin-top: 60px;
	}

	.blog_details.style_2 h2 {
		line-height: 1.2;
		letter-spacing: 0;
		padding: 0 50px 50px;
	}

	.contact_info_wrap_2 p {
		font-size: 18px;
	}
	.contact_info_list_2 a {
		font-size: 18px;
	}

	.about_section_4 h2, .about_section_4 h3 {
		font-size: 54px;
	}
	.about_section_4 p {
		font-size: 20px;
	}
	.award_item.style_2 p {
		font-size: 24px;
	}
	.about_section_4 hr {
		margin: 40px 0;
	}
	.calltoaction_section_2 {
		min-height: auto;
	}
	.calltoaction_section_2 a {
		font-size: 48px;
		letter-spacing: 0;
	}
	.calltoaction_section_2 h2 {
		font-size: 32px;
		letter-spacing: 0;
		margin-bottom: 10px;
	}
	.client_style_3 .col {
		padding: 15px;
	}

	.contact_form_2 h2 {
		font-size: 54px;
		letter-spacing: 0;
		margin-bottom: 50px;
	}
	.contact_form_2 {
		padding: 120px 0 80px;
	}

	.breadcrumb_style_4 {
		min-height: auto;
		padding: 150px 0 80px;
	}
	.breadcrumb_style_4 .page_title {
		font-size: 90px;
	}
	.breadcrumb_style_4 .big_text {
		bottom: 0;
		line-height: .7;
		font-size: 240px;
	}
	.about_section_6 .big_text {
		bottom: -100px;
		font-size: 170px;
	}

	.about_section_5 .about_image {
		margin-bottom: 50px;
	}
	.award_item.style_3 .item_title {
		font-size: 42px;
	}

	.contact_icon_box .item_content {
		font-size: 20px;
	}

	.contact_icon_box .item_icon {
		width: 90px;
		height: 90px;
		font-size: 24px;
		margin-right: 20px;
	}
	.contact_icon_box .item_content {
		font-size: 20px;
	}

	.join_content_wrap {
		padding: 100px 30px;
	}

	.portfolio_grid_layout .item_title {
		font-size: 28px;
		margin-bottom: 10px;
	}

	.team_item_3 .team_name {
		line-height: 1;
		font-size: 26px;
		letter-spacing: 0;
		margin-bottom: 8px;
	}
	.team_item_3 .team_title {
		line-height: 1;
		font-size: 16px;
		letter-spacing: 0;
	}

	.join_content_wrap h2 {
		line-height: 1;
		letter-spacing: 0;
	}
	.join_content_wrap p {
		letter-spacing: 0;
		margin-bottom: 50px;
	}

	.breadcrumb_style_5 {
		padding: 200px 0;
		min-height: auto;
	}
	.breadcrumb_style_5 .big_text {
		font-size: 200px;
	}

	.masonry_4col .element-item {
		width: 33.333%;
	}

	.portfolio_details_2 .item_title {
		font-size: 78px;
		margin-bottom: 40px;
		letter-spacing: -3px;
	}

	.blog_boxed_image .item_title {
		font-size: 18px;
	}

	.blog_post_author_2 {
		padding: 50px;
		margin-bottom: 60px;
	}

	.blog_details .blog_details_content p {
		font-size: 20px;
	}

	.section_title_2 {
		margin-bottom: 30px;
	}
	.section_space {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.backtotop {
		bottom: 22px;
		.scroll {
			width: 28px;
			height: 28px;
		}
	}

}
/* media screen and (max-width: 1024px) - end
================================================== */

/* media screen and (max-width: 991px) - start
================================================== */
@media screen and (max-width: 991px) {

	.header_style_1, .header_style_1.sticky {
		padding: 15px;
	}

	.load_more {
		margin-top: 45px;
	}

	.section_space {
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.section_title_2 p {
		font-size: 18px;
	}
	.section_title_2 .title_text {
		font-size: 54px;
		margin-bottom: 10px;
	}

	.banner_style_1 h1 {
		font-size: 60px;
		line-height: 1.2;
	}
	.banner_section {
		min-height: auto;
	}
	.banner_style_1 p {
		font-size: 20px;
		padding-right: 330px;
	}

	.portfolio_card_layout .item_content {
		padding: 20px 15px 15px;
	}
	.portfolio_card_layout .item_title {
		font-size: 20px;
	}

	.btn,
	.btn.btn_shadow {
		font-size: 14px;
	}

	.service_grid .item_title {
		font-size: 26px;
		margin-bottom: 10px;
	}

	.award_item .award_list a {
		font-size: 20px;
	}
	.award_item .year_text {
		font-size: 48px;
	}

	.client_item {
		padding: 0px;
	}

	.backtotop .scroll {
		width: 28px;
		height: 28px;
	}

	.backtotop {
		right: 22px;
	}

	.header_style_2 .main_menu,
	.header_style_2.sticky .main_menu,
	.header_style_5 .main_menu,
	.header_style_5.sticky .main_menu {
		padding: 15px;
	}
	.mobile_menu_btn {
		padding: 0;
		border: none;
		line-height: 1;
		font-size: 26px;
		border-radius: 0;
		&:focus {
			box-shadow: none;
		}
	}
	.header_btns_group > li:not(:last-child) {
		margin-right: 15px;
	}
	.main_menu_inner {
		left: 0;
		right: 0;
		position: fixed;
		background-color: #ffffff;
		border-top: 1px solid #f5f5f5;
		// box-shadow: 0 10px 10px 0 rgb(0, 0, 0, 0.02);
	}
	.main_menu_list > li,
	.main_menu_list > li:not(:last-child) {
		margin: 0;
	}
	.main_menu_list {
		padding: 15px;
		> li {
			width: 100%;
			display: block;
			&:not(:last-child) {
				border-bottom: 1px solid #f5f5f5;
			}
			a {
				font-size: 15px;
				padding: 15px 0;
			}
		}
	}
	.header_style_2 {
		.main_menu_inner {
			top: 90px;
		}
	}

	.main_menu_list .submenu {
		position: static;
		box-shadow: none;
		background-color: transparent;
	}
	.main_menu_list .submenu > li > a {
		padding: 15px 20px;
	}

	.order-last {
		order: 0!important;
	}

	.section_space_lg {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	.footer_style_2 .footer_bottom {
		padding-bottom: 30px;
	}

	.footer_style_2 .footer_widget_title {
		font-size: 26px;
		margin-bottom: 25px;
	}

	.white_content .mobile_menu_btn {
		color: #ffffff;
	}
	.white_content.sticky .mobile_menu_btn {
		color: $color_black;
	}
	.white_content.sticky .mobile_menu_btn:hover {
		color: $color_primary;
	}
	.header_style_2.white_content .main_menu_list > li > a {
		color: $color_black;
	}
	.header_style_2.white_content .main_menu_list > li:hover > a,
	.header_style_2.white_content .main_menu_list > li.active > a {
		color: $color_primary;
	}

	.header_style_3 .header_contact_info {
		display: none;
	}
	.play_btn_2 span svg {
		max-width: 16px;
	}

	.counter_item .count_text {
		font-size: 54px;
		margin-right: 20px;
	}
	.counter_item .item_title {
		font-size: 15px;
	}

	.testimonial_tab .nav {
		margin: 0 auto 30px;
	}
	.testimonial_item_1.style_2 {
		text-align: center;
	}

	.banner_style_5 h1 {
		font-size: 84px;
	}
	.banner_style_5 p {
		font-size: 20px;
	}
	.banner_style_5 .big_text {
		font-size: 290px;
	}

	.section_title_2 .focus_text {
		font-size: 100px;
	}

	.about_section_5 .about_image {
		margin-bottom: 40px;
	}
	.skill_item .designation {
		font-size: 14px;
		margin-top: 10px;
	}
	.calltoaction_section_3 h2 {
		font-size: 54px;
	}
	.award_item.style_3 {
		min-height: 403px;
		padding: 60px 40px;
	}
	.award_item.style_3 .item_title {
		font-size: 36px;
	}
	.client_items_group > li {
		width: 25%;
	}

	.footer_style_4 .contact_line {
		font-size: 66px;
	}

	.footer_style_4 .footer_contact_list > li:not(:last-child) {
		margin-bottom: 0;
	}
	.footer_style_4 .footer_info_list > li:not(:last-child) {
		margin-bottom: 10px;
	}
	.footer_style_4 .footer_bottom {
		padding: 30px 0;
	}

	.slider_section .social_area {
		display: none;
	}
	.slider_section {
		padding: 0;
	}
	.header_style_4 {
		padding: 15px;
		justify-content: space-between;
		&.sticky {
			padding: 15px;
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	.section_title p {
		font-size: 20px;
	}

	.breadcrumb_style_1 h1 {
		font-size: 120px;
	}
	.breadcrumb_style_1 p {
		font-size: 20px;
	}

	.portfolio_details h1 {
		font-size: 60px;
		letter-spacing: 0;
	}

	.portfolio_details .post_category {
		font-size: 22px;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}
	.portfolio_details .additional_info {
		padding: 50px 0;
	}
	.portfolio_details .additional_info h4 {
		font-size: 26px;
		margin-bottom: 10px;
	}

	.portfolio_details .details_content p {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.portfolio_details h2 {
		font-size: 54px;
	}
	.other_post_item h3 {
		font-size: 48px;
		letter-spacing: 0;
		margin-bottom: 35px;
	}
	.other_post_item h4 {
		font-size: 24px;
	}
	.other_post_item .item_content {
		padding: 30px;
	}

	.blog_fullwidth {
		min-height: auto;
		padding: 90px 15px;
	}
	.blog_fullwidth .post_meta {
		margin-bottom: 20px;
	}
	.blog_fullwidth .item_title {
		font-size: 42px;
	}

	.sidebar_section {
		padding: 30px;
		margin-top: 40px;
	}

	.blog_fullwidth .post_meta > li:not(:last-child),
	.blog_standard .post_meta > li:not(:last-child) {
		margin: 0 0 20px;
	}
	.blog_fullwidth .post_meta > li,
	.blog_standard .post_meta > li {
		width: 100%;
		display: table;
	}
	.blog_fullwidth .item_title {
		font-size: 30px;
	}
	.blog_standard .item_title {
		font-size: 24px;
	}
	.pagination_nav a {
		width: 50px;
		height: 50px;
		font-size: 16px;
	}

	.blog_details .blog_details_content p {
		font-size: 18px;
		margin-bottom: 30px;
	}
	.blog_details h2 {
		font-size: 34px;
		line-height: 1.4;
		letter-spacing: 0;
	}
	.blog_post_author {
		display: block;
		padding: 60px 0;
		margin-bottom: 50px;
	}
	.blog_post_author .follow_btn {
		width: auto;
		height: auto;
		position: static;
		transform: unset;
		margin-top: 20px;
		padding: 15px 40px;
	}
	.blog_post_author .image_wrap {
		width: 120px;
		height: 120px;
		margin: 0 0 30px;
	}
	.comment_form .form_item button {
		height: 86px;
		font-size: 30px;
		transform: unset;
		margin-top: 15px;
		position: static;
		line-height: 86px;
	}
	.comment_form .thumbnail_wrap {
		top: 0;
		transform: unset;
	}

	.breadcrumb_style_2 h1 span {
		margin-left: 0;
	}

	.about_section_2 .row {
		margin: -15px;
	}
	.about_section_2 .row .col {
		padding: 15px;
	}

	.service_primary p {
		font-size: 16px;
	}

	.section_title_3 .title_text {
		font-size: 42px;
	}

	.btn,
	.btn.btn_shadow {
		min-width: auto;
		padding: 20px 35px;
	}

	.portfolio_list_layout p {
		font-size: 16px;
	}

	.blog_3col_masonry .element-item {
		width: 50%;
	}

	.blog_details.style_2 .post_meta_2 > li:nth-child(1) {
		width: auto;
	}
	.blog_details.style_2 .blog_details_content p {
		font-size: 16px;
	}
	.contact_info_list_2 span {
		margin-right: 15px;
	}
	.contact_info_list_2 li:not(:last-child) {
		margin-bottom: 15px;
	}

	.about_section_7 .mission_content .item_title {
		line-height: 1;
		font-size: 30px;
		margin-bottom: 0;
	}
	.team_item_3 .team_content {
		padding: 20px;
	}
	.team_item_3 .team_name {
		line-height: 1;
		font-size: 26px;
		margin-bottom: 10px;
	}
	.team_item_3 .team_title {
		line-height: 1;
		font-size: 16px;
	}
	.section_title_4 {
		margin-bottom: 20px;
	}
	.about_section_7 .mission_content:not(:last-child) {
		margin-bottom: 30px;
	}
	.about_section_7 .photography_tabs_nav button {
		line-height: 2.3;
	}

	.header_style_5.white_content .main_menu_list > li > a {
		color: $color_default;
	}
	.header_style_5.white_content .main_menu_list > li:hover > a,
	.header_style_5.white_content .main_menu_list > li.active > a {
		color: $color_danger;
	}
	.header_style_5 .main_menu_inner {
		top: 90px;
	}

	.blog_fullwidth {
		padding: 150px 15px 80px;
	}

	.blog_details .blog_fullwidth {
		min-height: 500px;
	}
	.details_info li {
		font-size: 18px;
		padding-left: 30px;
	}
	.details_info li:before {
		top: 11px;
		width: 8px;
		height: 8px;
	}

	.join_content_wrap .form_item {
		display: block;
	}
	.join_content_wrap .form_item button {
		height: 70px;
		font-size: 16px;
		padding: 0 30px;
		line-height: 70px;
		margin-top: 20px;
	}

	.other_post_item_2 i.icon_left {
		left: 15px;
	}
	.other_post_item_2 i.icon_right {
		right: 15px;
	}

	.footer_style_2 .footer_widget_area {
		padding: 80px 0;
	}
	.footer_style_2 .brand_logo {
		margin-bottom: 30px;
	}

	.portfolio_list_layout_2 {
		display: block;
	}
	.portfolio_list_layout_2:nth-child(odd) .item_content,
	.portfolio_list_layout_2:nth-child(even) .item_content {
		padding: 40px 0 0;
	}

	.about_section h2 {
		font-size: 54px;
		margin: 40px 0 35px;
	}

	.section_title .title_text {
		font-size: 100px;
	}

	.breadcrumb_style_2 {
		padding: 140px 0 0;
	}

	.breadcrumb_style_1 {
		min-height: auto;
		padding: 200px 0 80px;
	}

	.portfolio_details .details_image {
		min-height: auto;
		padding: 200px 0 80px;
	}

	.breadcrumb_style_2.pb_space {
		padding: 130px 0 60px;
	}

	.blog_details.style_2 .blog_details_content {
		margin-top: -80px;
	}

}
/* media screen and (max-width: 991px) - end
================================================== */

/* media screen and (max-width: 767px) - start
================================================== */
@media screen and (max-width: 767px) {

	.banner_style_1 h1 {
		font-size: 54px;
	}
	.banner_style_1 p {
		padding-right: 0;
	}

	.section_title_2 .title_text {
		font-size: 48px;
		margin-bottom: 15px;
	}
	.section_title_2 p {
		font-size: 16px;
	}

	.banner_style_2 .tall_shape,
	.banner_style_3 .tall_shape {
		left: 0;
		width: 50%;
	}
	.banner_style_2 .deco_title_1,
	.banner_style_2 .deco_title_2,
	.banner_style_3 .deco_title_1,
	.banner_style_3 .deco_title_2 {
		font-size: 160px;
	}
	.banner_style_2 .deco_title_2,
	.banner_style_3 .deco_title_2 {
		top: 90px;
		left: -62px;
	}
	.banner_style_2 .deco_title_1,
	.banner_style_3 .deco_title_1 {
		right: 0;
		bottom: -50px;
	}

	.banner_style_2 h1, .banner_style_3 h1 {
		font-size: 100px;
	}

	.grid_col_3 .grid-item, .grid_col_3 .grid-sizer {
		width: 100%;
	}

	.testimonial_item_2 .content_wrap {
		padding: 40px 30px;
	}
	.testimonial_item_2 .testimonial_thumbnail {
		width: 100px;
		height: 100px;
		margin-bottom: 20px;
	}
	.testimonial_item_2 p {
		font-size: 18px;
		font-weight: 500;
	}
	.testimonial_item_2 .bottom_area {
		display: block;
		padding: 15px 30px;
	}

	.banner_style_4 h1 {
		font-size: 90px;
	}
	.banner_style_4 p {
		margin-bottom: 50px;
	}
	.play_btn_2 {
		margin-bottom: 20px;
		span {
			width: 60px;
			height: 60px;
		}
	}
	.banner_style_4 .flex_content_wrap {
		display: block;
	}

	.about_section_3 .circle_img_2 {
		top: -80px;
		right: -300px;
		max-width: 500px;
	}
	.about_section_3 .circle_img_1 {
		top: -60px;
		right: 220px;
		max-width: 120px;
	}

	.about_section_3 h2 {
		font-size: 48px;
		letter-spacing: 0;
	}
	.about_section_3 p {
		font-size: 18px;
	}
	.about_section_3 h2 span {
		font-size: 26px;
	}
	.play_btn_2 small {
		font-size: 18px;
	}

	.client_style_3 .col {
		padding: 15px;
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}
	.footer_style_3 .footer_bottom {
		padding-top: 60px;
	}
	.footer_style_3 {
		.footer_bottom .container {
			display: block;
			text-align: center;
		}
		.brand_logo {
			margin-bottom: 30px;
		}
	}

	.portfolio_list_layout_2 {
		display: block;
		margin-top: 60px;
	}
	.portfolio_list_layout_2:nth-child(odd) .item_content,
	.portfolio_list_layout_2:nth-child(even) .item_content {
		padding: 30px 0 0;
	}
	.portfolio_list_layout_2 .item_title {
		font-size: 42px;
		letter-spacing: 0;
		margin-bottom: 10px;
	}
	.portfolio_list_layout_2 p {
		font-size: 17px;
		margin-bottom: 20px;
	}
	.portfolio_list_layout_2 .item_category > li {
		font-size: 20px;
	}
	.portfolio_list_layout_2 .btn_text {
		font-size: 18px;
	}

	.award_item.style_3 {
		min-height: auto;
		padding: 50px 30px;
	}

	.client_items_group > li {
		width: 33.333%;
	}
	.footer_style_4 .footer_bottom {
		.container {
			display: block;
		}
		.social_primary {
			margin-top: 15px;
		}
	}

	.footer_style_4 .contact_line {
		font-size: 42px;
	}
	.footer_style_4 .footer_widget_area {
		padding: 50px 0;
	}

	.section_title_4 .title_text {
		font-size: 60px;
	}
	.about_section_7 .focus_text {
		bottom: 0;
		line-height: 1;
		font-size: 150px;
	}
	.portfolio_grid_layout .item_title {
		font-size: 24px;
		margin-bottom: 8px;
	}
	.portfolio_grid_layout .item_category > li:not(:last-child) {
		margin-right: 12px;
		padding-right: 12px;
	}
	.portfolio_grid_layout .item_content,
	.portfolio_grid_layout .item_date {
		padding: 20px;
	}
	.testimonial_item_4 p {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
	}
	.testimonial_item_4 .admin_image {
		width: 70px;
		height: 70px;
		margin-right: 15px;
	}

	.section_title .title_text {
		font-size: 100px;
	}
	.about_section p {
		font-size: 18px;
	}
	.about_section h2 {
		font-size: 66px;
		margin: 0 0 30px;
	}
	.about_section .image_wrap {
		margin-bottom: 40px;
	}
	.about_section h3 {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.team_item .team_content {
		padding: 20px;
	}
	.team_item .team_name {
		font-size: 20px;
	}
	.team_item p {
		font-size: 12px;
	}

	.calltoaction_section h3 {
		font-size: 48px;
	}

	.testimonial_item_1 p {
		font-size: 36px;
	}
	.testimonial_item_1 h3 {
		font-size: 24px;
		margin-bottom: 50px;
	}

	.portfolio_card_layout .item_title {
		font-size: 16px;
	}
	.portfolio_card_layout .item_category a {
		font-size: 14px;
	}
	.breadcrumb_style_1 h1 {
		font-size: 110px;
	}

	.portfolio_details h2 {
		font-size: 48px;
	}
	.portfolio_details .details_content p {
		font-size: 18px;
	}

	.portfolio_details .image_wrap {
		margin-bottom: 20px;
	}
	.portfolio_details h3 {
		font-size: 24px;
		font-weight: 600;
	}

	.other_post_item {
		display: block;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	.other_post_item h3 {
		font-size: 42px;
		margin-bottom: 25px;
	}

	figure {
		padding: 50px;
		margin-bottom: 40px;
	}
	figure .blockquote-footer {
		font-size: 20px;
		padding-top: 0;
	}
	.tag_share_links {
		display: block;
		margin-bottom: 60px;
	}
	.tag_share_links .sb_tag_list,
	.tag_share_links .item_share_links {
		display: block;
	}
	.tag_share_links h3 {
		margin: 0 0 15px;
	}
	.tag_share_links .sb_tag_list {
		margin-bottom: 30px;
	}
	.other_blogpost_wrap {
		display: block;
		margin-bottom: 80px;
	}
	.blog_other_post:not(:last-child) {
		margin-bottom: 20px;
	}
	.blog_other_post:nth-child(even) .arrow_btn {
		order: 0;
		margin: 0 10px 0 0;
	}
	.blog_other_post:nth-child(even) {
		text-align: left;
	}
	.blog_other_post:nth-child(odd) .arrow_btn {
		margin-right: 10px;
	}
	.blog_other_post .arrow_btn {
		height: 70px;
		flex: 0 0 70px;
	}
	.blog_other_post h4 {
		font-size: 20px;
	}
	.blog_other_post span {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.breadcrumb_style_2 h1 {
		font-size: 84px;
	}
	.breadcrumb_style_2 p {
		font-size: 20px;
	}

	.about_section_2 h2 {
		font-size: 48px;
		margin-bottom: 20px;
	}
	.about_section_2 p {
		font-size: 16px;
		margin-bottom: 20px;
	}

	.feature_section h2 {
		font-size: 36px;
	}
	.section_title_3 .title_text {
		font-size: 36px;
	}
	.service_boxed p {
		font-size: 16px;
	}
	.service_boxed .item_title {
		font-size: 26px;
	}

	.slick-dots li button {
		width: 10px;
		height: 10px;
	}
	.slick-dots li:not(:last-child) {
		margin: 0 15px 0 0;
	}

	.portfolio_list_layout:not(:last-child) {
		margin-bottom: 20px;
	}
	.portfolio_list_layout,
	.portfolio_list_layout .image_wrap {
		display: block;
	}
	.portfolio_list_layout {
		padding: 15px;
	}
	.portfolio_list_layout .item_content {
		padding: 20px 0 10px;
	}
	.portfolio_list_layout .item_title {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.portfolio_list_layout p {
		margin-bottom: 15px;
	}

	.breadcrumb_style_2 h1 {
		margin-bottom: 15px;
	}

	.calltoaction_boxed {
		padding: 70px 40px;
	}
	.calltoaction_boxed p {
		font-size: 18px;
		margin-bottom: 20px;
	}
	.calltoaction_boxed .mail_text {
		font-size: 30px;
	}

	.blog_details.style_2 h2 {
		padding: 0 0 50px;
	}

	.contact_info_wrap_2 p {
		font-size: 16px;
	}
	.contact_info_list_2 a {
		font-size: 16px;
	}

	.about_section_4 h2, .about_section_4 h3 {
		font-size: 42px;
	}
	.about_section_4 p {
		font-size: 18px;
	}
	.about_section_4 img {
		margin: 40px 0;
	}

	.breadcrumb_style_4 .big_text {
		font-size: 220px;
	}
	.breadcrumb_style_4 {
		padding: 150px 0 50px;
	}
	.breadcrumb_style_4 .page_title {
		font-size: 84px;
	}
	.about_section_6 .big_text {
		left: 0;
		bottom: -100px;
		font-size: 110px;
	}

	.testimonial_item_3 {
		padding: 0;
	}
	.testimonial_item_3 p {
		font-size: 30px;
	}

	.testimonial_carousel .carousel_arrow {
		padding-top: 50px;
	}

	.video_section {
		min-height: 450px;
	}
	.play_btn {
		width: 80px;
		height: 80px;
		svg {
			height: 22px;
		}
	}

	.about_section_7 .photography_tab_wrap {
		padding: 0;
	}
	.about_section_7 .photography_tabs_nav button {
		font-size: 16px;
		line-height: 2.2;
	}
	.about_section_7 .photography_tabs_nav {
		position: static;
		margin-bottom: 30px;
	}

	.breadcrumb_style_5 .big_text {
		font-size: 150px;
	}
	.breadcrumb_style_5 {
		padding: 200px 0 150px;
	}

	.masonry_4col .element-item {
		width: 50%;
	}
	.filter-btns-group {
		margin-bottom: 40px;
	}

	.other_post_item_2 {
		padding: 60px 30px;
	}
	.other_post_item_2 i {
		font-size: 42px;
	}
	.other_post_item_2 i.icon_left {
		left: 30px;
	}
	.other_post_item_2 i.icon_right {
		right: 30px;
	}
	.other_post_wrap {
		flex-wrap: wrap;
	}
	.other_post_wrap .other_post_item_2 {
		flex: 0 0 100%;
		&:not(:last-child) {
			border-bottom: 1px solid #000000;
		}
	}
	.other_post_wrap .other_post_item_2:first-child {
		text-align: right;
	}
	.other_post_wrap .other_post_item_2:last-child {
		text-align: left;
	}

	.blog_post_author_2 {
		display: block;
	}
	.blog_post_author_2 .image_wrap {
		margin: 0 0 30px 0;
	}

	.footer_style_1 .footer_bottom,
	.footer_style_2 .footer_bottom {
		display: block;
		padding-right: 50px;
		.copyright_text {
			padding-bottom: 25px;
		}
	}

	.service_grid .item_title {
		font-size: 20px;
	}

}
/* media screen and (max-width: 767px) - end
================================================== */

/* media screen and (max-width: 575px) - start
================================================== */
@media screen and (max-width: 575px) {

	.offcanvas_btn_1 .bar_icon small {
		height: 2px;
	}
	.offcanvas_btn_1 .menu_text {
		margin-right: 15px;
	}
	.offcanvas_sidebar {
		width: 300px;
		right: -320px;
		padding: 70px 20px 50px;
	}

	.btn,
	.btn.btn_shadow {
		min-width: auto;
		padding: 21px 40px;
	}

	.banner_style_1 h1 {
		font-size: 48px;
	}
	.banner_style_1 p {
		font-size: 18px;
		margin-bottom: 40px;
	}

	.section_title_2 .title_text {
		font-size: 42px;
	}
	.masonry_portfolio .element-item {
		width: 100%;
	}

	.select_option .nice-select {
		height: 56px;
		font-size: 16px;
		padding: 0 20px;
		line-height: 56px;
	}
	.form_item input {
		height: 56px;
		padding: 0 20px;
	}
	.form_item textarea {
		min-height: 160px;
		padding: 15px 20px;
	}

	.footer_section .brand_logo {
		margin-bottom: 20px;
	}

	.section_space_lg {
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.banner_style_2 .deco_item,
	.banner_style_3 .deco_item {
		display: none;
	}

	.line_decoration_y,
	.line_decoration_x {
		display: none;	
		}

	.offcanvas_btn_2 {
		width: 40px;
		height: 40px;
	}
	.offcanvas_btn_2 svg {
		max-width: 16px;
	}
	.header_style_2 .brand_logo {
		max-width: 100px;
	}
	.header_style_2 .main_menu_inner {
		top: 70px;
	}
	.mobile_menu_btn {
		font-size: 24px;
	}

	.progress_item .item_title, .progress_item .value_text {
		font-size: 15px;
	}
	.progress_item .value_text {
		top: -30px;
	}
	.progress_item .progress_bar {
		height: 4px;
		margin-top: -1px;
	}
	.progress_item .progress {
		height: 2px;
		margin-top: 12px;
	}

	.feature_section h2 {
		font-size: 36px;
		margin-bottom: 25px;
	}
	.feature_section p {
		font-size: 16px;
	}

	.header_style_3 .brand_logo .brand_link {
		max-width: 130px;
	}
	.about_section_3 h2 {
		font-size: 36px;
		margin-bottom: 25px;
	}
	.about_section_3 h2 span {
		font-size: 20px;
	}
	.about_section_3 p {
		font-size: 16px;
	}
	.grid_col_2 .grid-item, .grid_col_2 .grid-sizer {
		width: 100%;
	}
	.client_style_3 .col {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.testimonial_item_1.style_2 p {
		font-size: 30px;
	}

	.footer_style_3 .contact_info_list li:nth-child(2) {
		font-size: 30px;
	}
	.footer_style_3 .contact_info_list li:nth-child(3) {
		font-size: 20px;
	}
	.footer_style_3 .contact_info_list {
		padding: 40px 0;
	}
	.footer_style_3 {
		padding: 80px 0 40px;
	}

	.banner_style_5 .big_text {
		bottom: -40px;
		font-size: 180px;
	}
	.banner_style_5 h1 {
		font-size: 72px;
	}
	.banner_style_5 p {
		font-size: 18px;
	}
	.calltoaction_section_3 h2 {
		font-size: 42px;
	}
	.client_items_group .client_item {
		height: 120px;
	}
	.client_items_group > li {
		width: 50%;
	}
	.mouse_wheel_slider .slider_item p {
		opacity: 1;
		margin-bottom: 0;
	}

	.section_title .title_text {
		font-size: 80px;
		letter-spacing: -4px;
	}

	.about_section h2 {
		font-size: 54px;
	}
	.about_section p {
		font-size: 16px;
	}
	.about_section .image_wrap {
		margin-bottom: 20px;
	}

	.calltoaction_section h2 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	.calltoaction_section h3 {
		font-size: 42px;
		margin-bottom: 30px;
	}

	.section_title p {
		font-size: 18px;
	}
	.section_title {
		margin-bottom: 40px;
	}

	.video_section {
		min-height: 400px;
	}
	.play_btn {
		width: 80px;
		height: 80px;
		svg {
			max-width: 20px;
		}
	}

	.section_title_2 {
		margin-bottom: 40px;
	}

	.contact_form {
		padding-top: 0;
	}

	.breadcrumb_style_1 h1 {
		font-size: 90px;
		margin-bottom: 10px;
	}
	.breadcrumb_style_1 p {
		font-size: 18px;
	}

	.portfolio_details h1 {
		font-size: 42px;
	}

	.blog_details h2 {
		font-size: 26px;
	}
	.blog_details .blog_details_content p {
		font-size: 18px;
	}
	.comment_list .name_wrap {
		display: block;
	}
	.comment_list .comment_date {
		font-size: 14px;
	}
	.comment_form .form_item button {
		height: 70px;
		font-size: 26px;
		line-height: 70px;
	}

	.blog_boxed_wrapper {
		grid-template-columns: repeat(1, 1fr);
	}

	.breadcrumb_style_2 h1 {
		font-size: 72px;
	}
	.about_section_2 .image_wrap {
		margin-bottom: 30px;
	}

	.section_space {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.about_section_2 h2 {
		font-size: 36px;
	}

	.calltoaction_section p {
		font-size: 20px;
		margin-bottom: 25px;
	}
	.calltoaction_section .mail_text {
		font-size: 32px;
	}

	.breadcrumb_style_2 p {
		font-size: 18px;
	}

	.breadcrumb_style_2 {
		padding: 150px 0 0;
	}
	.breadcrumb_style_2 .deco_item {
		display: none;
	}

	.service_boxed {
		display: block;
	}
	.service_boxed .item_icon {
		margin-bottom: 25px;
	}

	.section_title_3 {
		margin-bottom: 40px;
	}

	.double_portfolio_wrap:not(:last-child) {
		margin-bottom: 30px;
	}
	.double_portfolio_wrap {
		display: block;
	}
	.double_portfolio_wrap .portfolio_fullimage:not(:last-child) {
		margin-bottom: 15px;
	}

	.filter-btns-group li:not(:last-child) {
		margin: 0 0 20px;
	}
	.filter-btns-group li {
		width: 100%;
		text-align: center;
	}

	.blog_details.style_2 .blog_details_content {
		margin-top: -30px;
		padding: 40px 20px;
	}
	.blog_details .area_title {
		font-size: 24px;
		letter-spacing: 0;
		margin-bottom: 30px;
		padding-bottom: 10px;
	}

	.calltoaction_section_2 a {
		font-size: 26px;
	}
	.calltoaction_section_2 h2 {
		font-size: 20px;
	}

	.about_section_4 h2, .about_section_4 h3 {
		font-size: 36px;
	}
	.about_section_4 p {
		font-size: 16px;
	}
	.about_section_4 span {
		font-size: 26px;
		margin-bottom: 20px;
	}
	.award_item.style_2 p {
		font-size: 18px;
	}

	.contact_form_2 h2 {
		font-size: 42px;
		margin-bottom: 30px;
	}
	.contact_form_2 {
		padding: 100px 0 80px;
	}

	.breadcrumb_style_4 .big_text {
		font-size: 130px;
	}
	.breadcrumb_style_4 .page_title {
		font-size: 54px;
	}
	.testimonial_item_3 p {
		font-size: 26px;
	}

	.text_sm_center {
		text-align: center;
	}

	.contact_icon_box .item_icon {
		width: 60px;
		height: 60px;
		font-size: 20px;
	}
	.contact_icon_box .item_content {
		font-size: 16px;
	}

	.breadcrumb_style_5 .big_text {
		font-size: 170px;
	}
	.breadcrumb_style_5 .page_title {
		font-size: 84px;
	}

	.header_style_5 .main_menu_inner {
		top: 88px;
	}

	.breadcrumb_style_5 .big_text {
		font-size: 110px;
	}
	.breadcrumb_style_5 {
		padding: 170px 0 80px;
	}
	.breadcrumb_style_5 .page_title {
		font-size: 72px;
	}

	.portfolio_details_2 .item_title {
		font-size: 66px;
	}

	.breadcrumb_style_1 p {
		font-size: 16px;
	}
	.breadcrumb_style_1 {
		padding: 160px 0 60px;
	}

	.social_wrap {
		display: block;
	}
	.social_title {
		margin: 0 0 10px 0;
	}

	.comment_list .comment_item,
	.comment_list .content_wrap .comment_item {
		display: block;
	}
	.comment_list .image_wrap {
		margin: 0 0 20px 0;
	}
	.map_section {
		iframe {
			height: 300px;
		}
	}

}
/* media screen and (max-width: 575px) - end
================================================== */

/* media screen and (max-width: 425px) - start
================================================== */
@media screen and (max-width: 425px) {

	.banner_style_1 h1 {
		font-size: 42px;
	}

	.award_item .award_list a {
		font-size: 18px;
	}

	.banner_style_2 h1, .banner_style_3 h1 {
		font-size: 65px;
		margin-bottom: 35px;
	}
	.banner_style_2 h1 span, .banner_style_3 h1 span {
		padding-left: 0;
	}
	.banner_style_2 p, .banner_style_3 p {
		font-size: 15px;
		margin-bottom: 20px;
	}
	.banner_style_2 .deco_title_1, .banner_style_2 .deco_title_2, .banner_style_3 .deco_title_1, .banner_style_3 .deco_title_2 {
		font-size: 100px;
	}
	.banner_style_2 .deco_title_2, .banner_style_3 .deco_title_2 {
		left: -30px;
	}
	.banner_style_2 .deco_title_1, .banner_style_3 .deco_title_1 {
		bottom: -30px;
	}

	.progress_item {
		margin-bottom: 30px;
	}

	.primary_section_title {
		font-size: 36px;
		margin-bottom: 30px;
	}

	.contact_area_2 h2 {
		font-size: 36px;
	}
	.contact_area_2 p {
		font-size: 16px;
	}

	.banner_style_4 h1 {
		font-size: 72px;
		margin-bottom: 20px;
	}
	.banner_style_4 p {
		font-size: 16px;
		margin-bottom: 30px;
	}
	.play_btn_2 span {
		width: 50px;
		height: 50px;
		font-size: 22px;
	}
	.play_btn_2 span svg {
		max-width: 12px;
	}
	.play_btn_2 small {
		font-size: 15px;
		margin-left: 10px;
	}
	.play_btn_2 {
		margin-bottom: 30px;
	}
	.banner_style_4 .social_primary a {
		font-size: 14px;
	}
	.about_section_3 h2 {
		font-size: 30px;
	}

	.testimonial_item_1.style_2 p {
		font-size: 24px;
	}

	.client_style_3 .col {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.footer_style_3 h2 {
		font-size: 30px;
	}
	.footer_style_3 .contact_info_list li:nth-child(2) {
		font-size: 24px;
	}

	.about_section_3 .about_content {
		padding-right: 0;
	}
	.testimonial_tab .nav li:nth-child(4) {
		right: 70px;
	}
	.testimonial_tab .nav {
		margin-bottom: 0;
		li:nth-child(1) {
			top: 0;
			left: 64px;
		}
	}
	.testimonial_tab .tab-content {
		padding-right: 0;
	}

	.banner_style_5 h1 {
		font-size: 60px;
	}
	.banner_style_5 .btn {
		min-width: auto;
		padding: 24px 40px;
	}
	.banner_style_5 .big_text {
		bottom: -30px;
		font-size: 120px;
	}
	.section_title_2 .focus_text {
		font-size: 76px;
	}
	.calltoaction_section_3 h2 {
		font-size: 30px;
	}

	.section_title_4 .title_text {
		font-size: 48px;
	}
	.about_section_7 p strong {
		font-size: 20px;
		line-height: 30px;
	}
	.about_section_7 p {
		margin-bottom: 20px;
	}
	.about_section_7 .focus_text {
		font-size: 84px;
	}
	.blog_item_boxed_2 .item_title {
		font-size: 24px;
		line-height: 30px;
	}
	.testimonial_item_4 {
		padding: 50px 30px;
	}
	.blog_item_boxed_2 {
		padding: 50px 30px;
	}
	.blog_item_boxed_2 p {
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 25px;
	}
	.blog_item_boxed_2 .post_admin .admin_name {
		line-height: 1;
		font-size: 16px;
	}
	.blog_item_boxed_2 .post_admin .admin_image {
		width: 55px;
		height: 55px;
		margin-right: 10px;
	}

	.spacer {
		height: 80px;
	}

	.section_title .title_text {
		font-size: 66px;
		letter-spacing: -2px;
	}

	.about_section h2 {
		font-size: 30px;
	}
	.about_section h3 {
		font-size: 18px;
		font-weight: 600;
	}

	.calltoaction_section h3 {
		font-size: 36px;
	}

	.breadcrumb_style_1 h1 {
		font-size: 72px;
	}

	.feature_section h2 {
		font-size: 30px;
		margin-bottom: 20px;
	}
	.service_primary .item_title {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.section_title_3 .title_text {
		font-size: 30px;
	}
	.section_title_3 p {
		font-size: 16px;
	}
	.service_boxed .item_title {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.intro_video .play_btn_2 {
		left: 20px;
		bottom: 20px;
	}

	.portfolio_fullimage .item_content {
		padding: 20px;
	}
	.portfolio_fullimage h3 {
		font-size: 20px;
		margin-bottom: 8px;
	}

	.calltoaction_section_2 a {
		font-size: 20px;
	}

	.testimonial_item_3 p {
		font-size: 22px;
	}

	.breadcrumb_style_5 .big_text {
		font-size: 138px;
	}
	.breadcrumb_style_5 .page_title {
		font-size: 72px;
	}
	.breadcrumb_style_5 {
		padding: 170px 0 70px;
	}
	.about_section_7 p strong {
		font-size: 18px;
	}

	.portfolio_details_2 .item_title {
		font-size: 54px;
	}
	.other_post_item_2 span {
		font-size: 30px;
		letter-spacing: 0;
	}

	.contact_info_list h3 {
		font-size: 26px;
	}
	.section_title_2 .title_text {
		font-size: 34px;
	}

	.blog_details.style_2 h2 {
		font-size: 22px;
		padding-bottom: 30px;
	}
	.blog_details.style_2 .related_post_wrap .blog_grid .item_title {
		font-size: 20px;
	}
	.blog_details.style_2 .related_post_wrap .blog_grid .btn_text {
		font-size: 16px;
	}

}
/* media screen and (max-width: 425px) - end
================================================== */

/* media screen and (max-width: 375px) - start
================================================== */
@media screen and (max-width: 375px) {

	.banner_style_1 h1 {
		font-size: 36px;
	}

	.portfolio_card_layout .item_title,
	.service_grid .item_title {
		font-size: 22px;
	}

	.header_style_1 .brand_logo .brand_link {
		max-width: 130px;
	}

	.banner_style_5 h1 {
		font-size: 50px;
	}

	.slider_section .slick-dots li {
		width: auto;
		line-height: 1;
	}
	.mouse_wheel_slider .slider_item p {
		font-size: 14px;
		line-height: 24px;
	}
	.mouse_wheel_slider .slider_item .item_title {
		line-height: 1;
		font-size: 24px;
	}
	.section_title_4 {
		margin-bottom: 36px;
	}
	.section_title_4 .title_text {
		font-size: 42px;
	}
	.about_section_7 .focus_text {
		font-size: 70px;
	}

	.section_title .title_text {
		font-size: 54px;
	}

	.testimonial_item_1 p {
		font-size: 30px;
		font-weight: 600;
	}

	.breadcrumb_style_2 h1 {
		font-size: 60px;
		margin-bottom: 15px;
	}

	.breadcrumb_style_5 .big_text {
		font-size: 100px;
	}
	.breadcrumb_style_5 .page_title {
		font-size: 60px;
	}
	.client_item {
		height: auto;
	}

}
/* media screen and (max-width: 375px) - end
================================================== */

/* media screen and (max-width: 320px) - start
================================================== */
@media screen and (max-width: 320px) {}
/* media screen and (max-width: 320px) - end
================================================== */